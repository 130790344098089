import React from 'react'
import AboutUsImg from '../../assets/images/AboutSouthAsiaConsultancy.webp'
import MainTitle from '../Common/MainTitle'
import LeftRedArrow from '../../assets/svg/LeftRedArrow'
import { Link } from "react-router-dom";
function SectionAboutUs() {
    return (
      <>
      <div className='container py-5'>
  <div className='row'>
  <div className='col-md-6'>
<img src={AboutUsImg} className='img-fluid' alt='aboutUS'/>
  </div>
  <div className='col-md-6'>
  <MainTitle text={'About South Asia Consultancy'} position={'start'} width={'80px'}/>
  <p className='SectionAboutText'>
  South Asia Consultancy  services have now expanded across a wide range to suit our client’s requirement. In a short span, have gained the confidence of Clients in India and Overseas. We are experts at delivering comprehensive business solutions, robust procedures, smooth operations, with QHSE having the highest priority with zero LTI.
  </p>
  <Link to="/about">
  <span className="s-primary d-flex align-items-center ">
  Read About SAC <LeftRedArrow />
            </span>
</Link>
  </div>
  </div>
      </div>
      </>  
    )
}

export default SectionAboutUs
