import React from 'react'

import ChatQuestion from 'assets/svg/ChatQuestion'
import SectionCapable from './SectionCapable'
function SectionServiceLayout1 ({imgUrl , content1 , content2 , content3 , CapableData}) {
    return (
        <>
        <div className='container'>
      <div className='row py-4'>
    <div className='col-md-5' >
   <button className='btn service-inner-btn'>
   Visa and Work Permit Management
   </button>
   <button className='btn service-inner-btn'>
   Crew Logistic Management
   </button>
   <button className='btn service-inner-btn'>
   Full-In Country Support
   </button>
   <button className='btn service-inner-btn'>
   Training and Compliance
   </button>
   <button className='btn service-inner-btn'>
   Payroll Management 
   </button>
    </div>
    <div className='col-md-7 '>
<img src={imgUrl} alt='img' className='img-fluid'  />
    </div>
      </div>
      <div className='row py-4'>
     <div className='col-md-5 '>
   <div className='any-question '>
    <div className='d-flex justify-content-center'>

    <ChatQuestion/>
    </div>
    <h1 className='fw600 text-center text-white fs32px'>
    Got any Questions?
Call us Today!
    </h1>
    <h4 className='fw600 text-center text-white fs26px'>
    <a href="tel:+919316305258" className="text-white">
                      (+91) 9316305258
                    </a>
    </h4>
    <h4 className='fw600 text-center text-white fs22px'>
    <a
                      href="mailto:info@southasiaconsultancy.com"
                      className="text-white"
                    >
                      info@southasiaconsultancy.com
                    </a>
    </h4>
   </div>
     </div>
     <div className='col-md-7'>
  <p className='fw500 fs20px lnh-34 text-justify '>
 {
  content1
 }
  </p>
  <p className='fw500 fs20px lnh-34 text-justify '>
 {
  content2
 }
  </p>
  <p className='fw500 fs20px lnh-34 text-justify '>
  {
    content3
  }
  </p>
     </div>
      </div>

        <SectionCapable CapableData ={CapableData}   />
        </div>
        </>
    )
}

export default SectionServiceLayout1
