import LocationLogoContactUs from 'assets/svg/LocationLogoContactUs'
import React from 'react'

function SectionAddress() {
    const ContactAddressData = [ 
        {
        title: 'Regional Office',
        address: '1304, Platinum 31, D N Nagar, Andheri West, Mumbai, Maharashtra 400053',
        email: 'info@southasiaconsultancy.com'
    },
    {
        title: 'Dubai Office',
        address: `South Asia Consultancy FZE
        Saif  Plus R6 - 11/B P O BOX 120197
        Sharjah - U.A.E.`,
        email: 'info@southasiaconsultancy.com'
    },
    {
        title: 'Operation Office',
        address: '619 , Palladium Business Hub , Opposite 4D,  Chandkheda, Ahmedabad, Gujarat 382424',
        email: 'info@southasiaconsultancy.com'
    }

]
    return (
        <>
        <div className='container' >
   <div className='row py-4'>
  <div className='contact-address'>
 <div className='d-flex justify-content-center align-items-center'>
<LocationLogoContactUs/>
<h2 className='primary-color mx-2'>
Registered Office
</h2>

 </div>
 <div className='d-flex justify-content-center mt-2 '>

 <p className=' contact-us-address fs20px lnh-32 text-center'>
402, Orange Mall, Mehsana Highway, above HDFC Bank, Nigam Nagar, Chandkheda, Ahmedabad, Gujarat 382424
</p>
 </div>
 <div>
    <p className='primary-color text-center fw600 fs16px'> 
    +91 9316305258    ||    info@southasiaconsultancy.com
    </p>
 </div>
  </div>
   </div>

   <div className='row py-4'>
    {
        ContactAddressData?.map((data) => (<div className='col-md-4'>
        <div className='contact-us-card'>
        <h1 className='text-center fw600 fs22px primary-color'>
       {data?.title}
        </h1>
        <p className='text-center fs18px fw500'>
{
    data?.address
}
        </p>
        <h1 className='text-center fw600 fs16px primary-color'>
      {data?.email}
        </h1>
        </div>
        </div>))
    }

   </div>
        </div>
        
        </>
    )
}

export default SectionAddress
