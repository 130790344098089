import ServiceButton from 'components/Common/ServiceButton'
import React from 'react'

function SectionServiceLayout4({content1, buttonText, imgUrl, title }) {
    return (
      <>
    <div className='container'>
        <div className='row py-4'>
  <p className='fw500 fs20px'>
{
    content1
}
  </p>
  <div className='row'>
{
  buttonText?.map((data)=>(<>
  <div className='col-md-6'>
<ServiceButton title={data?.title}/>
  </div>
  
  </>))
}
  </div>
  <div className='row py-4'> 
<img src={imgUrl}  className='img-fluid' alt ={title} />
  </div>
        </div>
        </div>  
      
      
      </>  
    )
}

export default SectionServiceLayout4
