import React from "react";
import { Form } from "react-bootstrap";

const SectionJobApply = () => {
  return (
    <>
      <div>
        <div className="container pt-4">
          <div className="row py-4 ">
            <div className="col-md-6 ">
              <h5 className="font-extrabold">No job? You can still apply</h5>
              <p className="text-justify">
                SAC believes in professional & friendly work culture so
                employees can deliver their excellence with satisfaction.   We 
                encourages employees to put their opinion to make the work
                culture more comfortable & positive with colleagues.   We have
                the range of excellent technical & Non- technical crew personnel
                who cooperate & empowered each-other, which makes our work
                atmosphere different from others. SAC always supports the
                employees advancement & plan for well-defined career path.  We
                provides multiple career options, guidance  to employees for
                their career to make worklife simpler , pleasant & More
                productive. 
              </p>
            </div>
            <div className="col-md-6">
              <form>
              <div>
                  <input
                    type="text"
                    placeholder="Enter position you are applying for"
                    className="width512 p-2 mb-4"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="width512 p-2 mb-4"
                  />
                </div>
              

                <div>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="width512 p-2 mb-3"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Enter your phone number here"
                    className="width512 p-2 mb-3"
                  />
                </div>

<Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Attach your resume *</Form.Label>
        <Form.Control type="file" />
      </Form.Group>
              

                <div className="">
                  <button className=" btn   ContactPageBtn  my-4">
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionJobApply;
