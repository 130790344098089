import React from "react";

function BottomBorder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="3"
      fill="none"
      viewBox="0 0 84 3"
    >
      <path
        stroke="maroon"
        strokeLinecap="round"
        strokeWidth="3"
        d="M2 1.5h80"
      ></path>
    </svg>
  );
}

export default BottomBorder;