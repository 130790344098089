import React from 'react'

function MainTitle({text, className , position, width }) {

    return (
        <>
    <h1 className={`${className} MainTitle `}  > {text}  </h1>  
    {
        position && 
        <div className={`d-flex justify-content-${position}`}>
        <p className='border-title' style={{ width: `${width}` }} ></p>
            </div> 
    }
    
        
        </>
    )
}

export default MainTitle
