import React from "react";

function HelloIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="11"
    fill="none"
    viewBox="0 0 15 11"
  >
    <path
      fill="url(#paint0_radial_554_1090)"
      d="M5.851 2.704c.443-.384 1.565-1.295 2.501-1.536.24-.062.457-.091.66-.091.364 0 .627.097.88.19.226.083.46.17.74.17.122 0 .244-.017.372-.05.163-.042.327-.15.5-.264.253-.165.54-.353.852-.353.256 0 .509.124.775.38l.821.771c.407.41.434.968.385 1.363-.121.97-.851 2.117-1.682 2.658L5.851 2.704z"
    ></path>
    <path
      fill="#EDA600"
      d="M12.356.946c.208 0 .421.11.655.335l.815.766c.287.291.4.7.335 1.217-.111.893-.765 1.943-1.522 2.478l-6.47-3.08c.515-.427 1.441-1.123 2.225-1.324.226-.058.428-.087.618-.087.331 0 .568.087.818.18.241.088.49.18.8.18.138 0 .274-.018.417-.055.191-.05.367-.164.553-.286.245-.158.497-.324.756-.324zm0-.351c-.586 0-1.04.53-1.397.622-.118.03-.227.043-.328.043-.535 0-.885-.36-1.618-.36-.202 0-.433.026-.706.097-1.25.32-2.771 1.75-2.771 1.75l7.13 3.395c1.354-.812 2.55-3.196 1.406-4.348l-.82-.77c-.329-.317-.626-.43-.896-.43z"
    ></path>
    <path
      fill="url(#paint1_radial_554_1090)"
      d="M7.792 10.145a.764.764 0 01-.498-.185L1.702 5.018C1.06 4.451.666 3.742.646 3.12c-.014-.443.157-.847.508-1.201l.816-.765c.267-.258.52-.383.776-.383.311 0 .599.188.852.354.173.114.337.222.5.264.128.033.25.05.373.05.279 0 .512-.087.738-.17.253-.094.516-.19.879-.19.205 0 .42.03.66.09 1.193.307 2.68 1.695 2.696 1.71l3.305 2.94a.788.788 0 01.092 1.104l-.023.028a.766.766 0 01-.94.188.172.172 0 00-.19.019.175.175 0 00-.06.18.786.786 0 01-.16.7l-.024.027a.765.765 0 01-1.058.113.175.175 0 00-.197-.013.175.175 0 00-.084.18.789.789 0 01-.173.627L9.908 9a.766.766 0 01-1.044.123.17.17 0 00-.104-.034.175.175 0 00-.176.19.776.776 0 01-.182.566l-.023.028a.767.767 0 01-.587.272z"
    ></path>
    <path
      fill="#EDA600"
      d="M2.745.946c.26 0 .512.166.755.325.187.122.362.237.554.286.142.037.278.055.417.055.31 0 .559-.093.799-.18.25-.093.486-.18.818-.18.19 0 .391.028.617.087 1.148.294 2.606 1.652 2.62 1.666a.031.031 0 00.007.006l3.3 2.938.005.005a.61.61 0 01.07.854l-.024.028a.59.59 0 01-.724.147.357.357 0 00-.38.037.351.351 0 00-.123.361.612.612 0 01-.123.543l-.023.028a.59.59 0 01-.816.086.35.35 0 00-.562.333.604.604 0 01-.135.488l-.024.028a.59.59 0 01-.805.096.354.354 0 00-.38-.026.352.352 0 00-.18.335.605.605 0 01-.14.44l-.024.028a.593.593 0 01-.834.074L1.815 4.888C1.211 4.353.838 3.691.82 3.117c-.013-.396.136-.746.454-1.068l.819-.769c.232-.225.445-.334.653-.334zm0-.351c-.27 0-.568.112-.897.43l-.82.77c-1.143 1.152-.32 2.58.556 3.355l5.595 4.945a.94.94 0 001.336-.11l.023-.028c.169-.2.242-.45.222-.693a.94.94 0 001.283-.152l.023-.028a.957.957 0 00.212-.769.942.942 0 001.301-.137l.024-.028a.959.959 0 00.194-.856.94.94 0 001.153-.232l.024-.028a.962.962 0 00-.11-1.347l-3.3-2.938S8.044 1.318 6.795.998A2.8 2.8 0 006.087.9c-.733 0-1.082.36-1.618.36-.102 0-.21-.012-.329-.043-.357-.094-.81-.623-1.396-.623z"
    ></path>
    <path
      fill="url(#paint2_radial_554_1090)"
      d="M3.554 8.178a.745.745 0 01-.616-.328.762.762 0 01.194-1.051l1.287-.89a.736.736 0 011.037.196.762.762 0 01-.194 1.051l-1.287.89a.731.731 0 01-.42.132z"
    ></path>
    <path
      fill="#EDA600"
      d="M4.84 5.954a.57.57 0 01.471.25.585.585 0 01-.149.808l-1.287.89a.563.563 0 01-.792-.15.585.585 0 01.15-.808l1.286-.89a.563.563 0 01.32-.1zm0-.352c-.18 0-.361.053-.522.163l-1.286.89a.935.935 0 00-.24 1.293.918.918 0 001.284.242l1.287-.89c.42-.29.528-.87.24-1.294a.923.923 0 00-.763-.404z"
    ></path>
    <path
      fill="url(#paint3_radial_554_1090)"
      d="M6.218 10.23a.742.742 0 01-.496-.19.763.763 0 01-.062-1.068l.749-.849a.741.741 0 011.054-.062.763.763 0 01.062 1.068l-.75.848a.745.745 0 01-.557.253z"
    ></path>
    <path
      fill="#EDA600"
      d="M6.968 8.047c.14 0 .274.051.378.145a.586.586 0 01.047.82l-.748.849a.568.568 0 01-.806.048.586.586 0 01-.046-.82l.748-.849a.568.568 0 01.427-.193zm0-.352a.92.92 0 00-.69.312l-.75.848a.936.936 0 00.077 1.314.92.92 0 001.303-.076l.749-.848a.936.936 0 00-.076-1.314.913.913 0 00-.613-.236z"
    ></path>
    <path
      fill="url(#paint4_radial_554_1090)"
      d="M2.615 6.934a.735.735 0 01-.631-.354.755.755 0 01.24-1.033l.784-.49a.735.735 0 011.02.241.754.754 0 01-.24 1.032l-.784.49a.72.72 0 01-.39.114z"
    ></path>
    <path
      fill="#EDA600"
      d="M3.397 5.122c.197 0 .378.1.48.27a.578.578 0 01-.183.791l-.783.489a.559.559 0 01-.776-.185.578.578 0 01.184-.791l.784-.49a.554.554 0 01.294-.084zm0-.352a.905.905 0 00-.481.138l-.784.49a.929.929 0 00-.297 1.273.915.915 0 001.262.3l.784-.49a.929.929 0 00.297-1.273.913.913 0 00-.78-.438z"
    ></path>
    <path
      fill="url(#paint5_radial_554_1090)"
      d="M4.649 9.54a.719.719 0 01-.513-.215.741.741 0 010-1.04L5.51 6.899a.719.719 0 011.027 0 .74.74 0 010 1.04L5.162 9.325a.719.719 0 01-.513.214z"
    ></path>
    <path
      fill="#EDA600"
      d="M6.025 6.86c.146 0 .284.057.389.162a.564.564 0 010 .792L5.037 9.201a.546.546 0 01-.778 0 .564.564 0 010-.792L5.634 7.02a.55.55 0 01.39-.162zm0-.352c-.231 0-.462.089-.64.266L4.01 8.16a.915.915 0 000 1.287.901.901 0 001.277 0l1.376-1.387a.915.915 0 000-1.287.901.901 0 00-.638-.266z"
    ></path>
    <mask
      id="mask0_554_1090"
      style={{ maskType: "luminance" }}
      width="6"
      height="4"
      x="5"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        d="M5.253 3.476c-.263-.456-.112-.988.378-1.291L8.359.97c.3-.11.636.606.636.606l.477.876s.428.627.475.685c.241.291-.124-.084-.625-.194-.886-.193-2.223.874-2.863.988-.64.114-.942 0-1.206-.456z"
      ></path>
    </mask>
    <g mask="url(#mask0_554_1090)">
      <path
        fill="url(#paint6_linear_554_1090)"
        d="M6.057 3.798c-.307 0-.478-.107-.652-.41-.217-.374-.093-.794.307-1.048l2.71-1.206c.112-.039.289-.062.489-.062.59 0 1.153.181 1.23.396l.014.03.864 1.464.002.016c-.005 0-.071.045-.302.045-.333 0-.85-.095-1.346-.247a1.15 1.15 0 00-.337-.048c-.556 0-1.19.34-1.75.64-.337.182-.657.353-.86.39a2.03 2.03 0 01-.369.04z"
      ></path>
      <path
        fill="#EDA600"
        d="M8.912 1.248c.583 0 1.02.181 1.065.28a.277.277 0 00.028.06l.745 1.26h-.028c-.317 0-.814-.091-1.295-.24a1.343 1.343 0 00-.388-.055c-.602 0-1.257.352-1.833.662-.31.168-.633.34-.808.372a2.023 2.023 0 01-.34.035c-.217 0-.34-.047-.5-.321-.166-.289-.072-.603.24-.805l2.691-1.198c.091-.032.248-.05.423-.05zm0-.352c-.22 0-.42.025-.554.074L5.63 2.184c-.49.303-.641.835-.378 1.291.202.348.426.497.805.497.118 0 .25-.014.401-.04.574-.103 1.723-1.029 2.58-1.029.1 0 .194.013.284.04.464.143 1.015.256 1.398.256.333 0 .542-.086.46-.31l-.873-1.48c-.123-.349-.83-.513-1.395-.513z"
      ></path>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_554_1090"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(.5314 4.4676 -3.45015 .41037 10.113 1.891)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.599" stopColor="#FFCA28"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </radialGradient>
      <radialGradient
        id="paint1_radial_554_1090"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(96.783 2.214 4.45) scale(7.12245 5.50039)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.599" stopColor="#FFCA28"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </radialGradient>
      <radialGradient
        id="paint2_radial_554_1090"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(144.745 1.476 3.988) scale(1.94542 1.28339)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.599" stopColor="#FFCA28"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </radialGradient>
      <radialGradient
        id="paint3_radial_554_1090"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(-1.09388 1.2706 -.9564 -.8234 7.125 8.4)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.599" stopColor="#FFCA28"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </radialGradient>
      <radialGradient
        id="paint4_radial_554_1090"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(-1.2598 .78315 -.57788 -.9296 3.578 5.564)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.599" stopColor="#FFCA28"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </radialGradient>
      <radialGradient
        id="paint5_radial_554_1090"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(45.239 -5.55 11.095) scale(1.67489 2.01008)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.599" stopColor="#FFCA28"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </radialGradient>
      <linearGradient
        id="paint6_linear_554_1090"
        x1="7.205"
        x2="8.558"
        y1="1.848"
        y2="4.299"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.599" stopColor="#FFCA28"></stop>
        <stop offset="1" stopColor="#FFB300"></stop>
      </linearGradient>
    </defs>
  </svg>
  );
}

export default HelloIcon;