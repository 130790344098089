import React from 'react'

function SectionServiceLayout5({liText, title , imgUrl, content1}) {
    return (
       <>
    <div className='container' >
        <div className='row py-4'>
        <ul>
                    {liText && liText?.map((item) => (<>
                    <div className="d-flex">
                        <div className=" NewsIcon">
                        <i class="fa fa-circle" aria-hidden="true"></i></div>
                        <li className="serviceList">
                            {item.title}   
                        </li>
                        </div>
                        </>
                    ))}
                </ul>
        </div>
        <div className='row py-4'>
   <div className='col-md-6'>
<img src={imgUrl} alt={title}  className='img-fluid'/>
   </div>
   <div className='col-md-6'>
   <ul>
                    {content1 && content1?.map((item) => (<>
                    <div className="d-flex">
                        <div className=" NewsIcon">
                        <i class="fa fa-circle" aria-hidden="true"></i></div>
                        <li className="serviceList">
                            {item.title}   
                        </li>
                        </div>
                        </>
                    ))}
                </ul>
   </div>
        </div>
        </div>   
       
       
       </> 
    )
}

export default SectionServiceLayout5
