import React from 'react'

function SliderRightArrow() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="32"
        fill="none"
        viewBox="0 0 18 32"
      >
        <path
          fill="#11467A"
          d="M17.12 15.01l-14-14a1.4 1.4 0 10-1.98 1.98L14.15 16 1.14 29.01a1.4 1.4 0 001.98 1.98l14-14a1.4 1.4 0 000-1.98z"
        ></path>
      </svg>
    )
}

export default SliderRightArrow
