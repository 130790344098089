import LayoutOne from 'layouts/LayoutOne'
import React from 'react'
import { useParams } from "react-router-dom";
import ShahImg from '../assets/images/chiragShahsir.png'
import ProfileImg2 from '../assets/images/Manishaben.png'
import ProfileImg3 from '../assets/images/Advocate.png'
import MainTitle from 'components/Common/MainTitle';
import ProfileImg4 from '../assets/images/Mshaikh1.png'
const ProfileDetailsPage = () => {
    const { id } = useParams();
    // console.log(id, 'id')
    const Data =[
        {
            id: '2',
            name: "Manisha Singh Dhami",
            description: "14 years of experience in excecution & implementation of operational procedures in Oil & Gas segment.Excellent interpersonal communication,leadership & analytical management skills. Ability to deal with project challenges, team & clients effectively.",
            imgUrl: ProfileImg2
        },
        {
            id: '1',
            name: "Mr. Chirag Shah",
            description: "Chief Financial Officer having a strong set of hard skills – technical and analytical competence – and soft skills, such as strategic communication, commercial, interpersonal, and managerial competence. He has more than two decades of working experience and also has been with the company since incorporation. Our CFO represents a functional position that supports the business his knowledge and experience of the industry is critically important and under his guidance finance section of organization is getting stronger and constantly growing.",
            imgUrl: ShahImg
        },
        {
            id: '3',
            name: "Adv. Mayank Shah",
            description: "Meet Adv. Mayank Shah, a legal luminary boasting LLB, Company Secretary, and B-Com degree. His seasoned expertise enriches our practice, with a stronghold in civil and criminal law, Mayank's litigation mastery forms our legal bedrock.",
            imgUrl : ProfileImg3
        },
        {
          id: '4',
          name: "Mr. M. A. Sheikh",
          description: "Mr. M. A. Sheikh is Vice President – Operations at SAC. In this role, he brings industry leading practices with a vision of delivering projects within budget and defined timelines. He has more than one and half decade of rich and diverse experience in various strategic and operations management positions. He has wide range of experience in Drilling & Workover Operations, Oil and Gas Exploration and Production etc. He has vast experience in other disciplines like Business Development, Contracts and Tendering. He has led and managed E&P divisions of Small Oil and Gas Field Operators. He is also leading the E&P Division of SACF.  He has done his Bachelor’s in Petroleum Engineering from Dibrugarh University.",
          imgUrl : ProfileImg4
      },
    ]
    const profileData = Data?.filter((item) => item?.id === id)
    console.log(profileData[0]?.imgUrl, 'profile');
  return (
    <>
      <LayoutOne>

    <div className='container py-5'>
    <div className='py-3 d-flex justify-content-center'>
 {
  profileData[0]?.imgUrl && 
<img src={profileData[0]?.imgUrl} className='img-fluid'  alt='profile-img' />
 }
      </div>
      {
        profileData[0]?.name &&
    <MainTitle  text={profileData[0]?.name} className={'text-center'} />
      }
<div>
  {
      profileData[0]?.description  && 
      <p className='text-justify'>
      {
        profileData[0]?.description
      }
    </p>
  }
 
</div>
    </div>
      </LayoutOne>
    </>
  )
}

export default ProfileDetailsPage
