import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";


function ServiceCardLayout1({CardData}) {

  
    return (
       <>
      <div className='container'>
      
        <div className='row py-4'>

        {
  CardData?.map((card, index) => (
    <div className="col-md-6" key={index}>
   <Card  className='card-services-offered'>
   <Link to={`/service/${card?.slug}`}>
      <Card.Img variant="top"  className='card-service-img' src={card?.CardImg}  />
</Link>
      <Card.Body>
        <Card.Title className='SectionServiceCardTitle'>
        <Link to={`/service/${card?.slug}`}>
            {card?.Title}
            </Link>
            </Card.Title>
        <div className='service-card-content'>

        <p className='text-ellipsis'>
         {
             card?.content
            }
        </p>
            </div>
        <span className='color-primary read-more-underline'>
            <Link to={`/service/${card?.slug}`}>
            Read More
            </Link>
        </span>
        </Card.Body>
    </Card>
    </div>
  ))
}

  
        </div>
        </div> 
       
       </> 
    )
}

export default ServiceCardLayout1
