import React from 'react'
import PartnerLogo1 from '../../assets/images/Partner1.webp'
import PartnerLogo2 from '../../assets/images/Partner2.webp'
import PartnerLogo3 from '../../assets/images/Partner3.webp'
import PartnerLogo4 from '../../assets/images/Partner4.webp'
import PartnerLogo5 from '../../assets/images/Partner5.webp'
import PartnerLogo6 from '../../assets/images/Partner6.webp'
import PartnerLogo7 from '../../assets/images/Partner7.webp'
import PartnerLogo8 from '../../assets/images/Partner8.webp'
import PartnerLogo9 from '../../assets/images/Partner9.webp'
import PartnerLogo10 from '../../assets/images/Partner10.webp'
import PartnerLogo11 from '../../assets/images/Partner11.webp'
import PartnerLogo12 from '../../assets/images/Partner12.webp'
import PartnerLogo13 from '../../assets/images/Partner13.webp'
import PartnerLogo14 from '../../assets/images/Partner14.webp'
import PartnerLogo15 from '../../assets/images/Partner15.webp'
import PartnerLogo16 from '../../assets/images/Partner16.webp'
import PartnerLogo17 from '../../assets/images/Partner17.webp'
import Marquee from 'react-fast-marquee'

function SectionPartner() {
   const logoData = new Map([
      ['Samsung', PartnerLogo1],
      ['Myntra', PartnerLogo2],
      ['Tata', PartnerLogo3],
      ['Puma', PartnerLogo4],
      ['Ongc', PartnerLogo5],
      ['JTI', PartnerLogo6],
      ['key', PartnerLogo7],
      ['Punj', PartnerLogo8],
      ['Dilex', PartnerLogo9],
      ['radial', PartnerLogo10],
      ['stonehill', PartnerLogo11],
      ['nevo', PartnerLogo12],
      ['vedanta', PartnerLogo13],
      ['cairn', PartnerLogo14],
      ['ksdriling', PartnerLogo15],
      ['olix', PartnerLogo16],
      ['Liyod', PartnerLogo17],
    ])
    return (
       <>
       <div className='section-partner pb-4'>
       <h1 className='section-partner-title pt-3'>
     Partners who trust South Asia Consultancy Services
     </h1>
     
     <Marquee speed={100}  loop={0}>
          {/* Iterate over the Map entries and render each logo */}
          {Array.from(logoData.entries()).map(([company, logo]) => (
            <div key={company} className="logoMarquee mx-2">
              <img src={logo} alt={company} />
            </div>
          ))}
        </Marquee>
       </div>
       
       </> 
    )
}

export default SectionPartner
