import MainTitle from "components/Common/MainTitle";
import React from "react";
import JTILogo from "../../assets/images/JTI.png";
import Strata from "../../assets/images/Strata.png";
import NeuVo from "../../assets/images/Neuvo.png";
import keyEnergy from "../../assets/images/key-Energy.png";
import SeLan from "../../assets/images/SELAN.png";
import OnGc from "../../assets/images/ONGC.png";

function SectionCompletedProjects() {
  const CompletedProjectContent = [
    {
      imgUrl: JTILogo,
      content: [
        "Operation and Maintenance of 75 Ton Workover Rig at Dhokla Field of Joshi TechnologiesInternational Inc., India.",
      ],
    },
    {
      imgUrl: Strata,
      content: [
        "Supply of Manpower for Managing Jack-up Rig “Seaboss” in the Persian Gulf, Iran.",
        "Supply of Manpower for Managing Jack-up Rig “DCI-1” in the Persian Gulf, Iran.",
        "Supply of Manpower for Managing Jack-up Rig “DCI-2” in the Persian Gulf, Iran.",
      ],
    },
    {
      imgUrl: NeuVo,
      content: [
        "Supply of Manpower for Managing Jack-up Rig “Paradise 400” in the Persian Gulf, Iran.",
      ],
    },
    {
      imgUrl: keyEnergy,
      content: [
        "Supply of Manpower for Onshore Drilling Rig in Key Energy, Bahrain.",
      ],
    },
    {
      imgUrl: SeLan,
      content: [
        "Crude Oil Transportation Services to Transport Selan’s Crude from Bakrol, Lohar and KarjasanField, India.",
        "End To End Solution To Maintain Surface Facilities Of Production, SRP Operations, Crude Oil Transportation, HighPressure Mobile Air Compressor Unit, DG Sets & Duel Fuel Engines For SRP Units And Other EPS Management &Fabrication System For Selan Exploration Technology Limited.",
      ],
    },
    {
      imgUrl: OnGc,
      content: [
        "DG Sets Services For SRP Units Of ONGC Cambay Asset, Gujarat, India.",
        "O&M Of CBHP Cementing Services of ONGC Cambay Asset, Gujarat, India.",
      ],
    },
  ];
  const CompletedProjectList = [
    "Operation and maintenance for 04 No. of 3000 HP 6th gen offshore jack-up drilling rig in Persian Gulf for PGFK, IOOCand Drilling Company International.",
    "Operation and maintenance of 3000 HP 6th generation NOV Jack up rig, Name: SEABOSS",
    "Operation and maintenance of 2000 HP Amphion Jack up rig, Name: Paradise 400",
    "Operation and maintenance of 2000 HP Cyber Base touch screen Jack up Rig, Name: DCI-1",
    "Operation and maintenance of 2000 HP Cyber Base touch screen Jack up Rig, Name: DCI-2",
    "Operation & maintenance of Of 500 HP Work Over Rig for Joshi Technology International in India.",
    "Operation & maintenance of Of 500 HP Work Over Rig for Joshi Technology International in India.",
    "Provided Charter hire services of 01 nos of 750 HP & 01 nos of 900 HP workover rig on a firm contract period of 3years to Cairn India Ltd.",
    "Successfully completed Operation & Maintenance of a 2200 HP Offshore jack up Drilling rig for CDPL (KAZAKHSTAN)",
    "Successfully completed Operation & Maintenance of a 1500 HP Drilling rig for Stonehill Nigeria Limited",
    "Operation & Maintenance of a 500 HP Workover rig for WILAYAT KEY ENERGY (OMAN).",
    "Operation & MaintenanceOf A 750 HP Workover Rig For ALGHANIM (KUWAIT)",
    `Operation & MaintenanceOf Two No's Of 750 HP Workover Rig For CACTUS (OMAN).`,
    `Operation & Maintenance Of Two No's Of 550 Hp And Two No's Of 650 Hp Workover Rig For Key Energy (Baharain)`,
    `Full Project Management For Drilling And Workover Program Of Oilex India Project.`,
    `Operation And Maintenance Of 75 Ton Workover Rig Of M/S. Joshi Technology International Inc., India.`,
    `Running IWCF Training School, India.`,
  ];
  return (
    <>
      <div className="container pt-4 ">
        <MainTitle
          text="Completed Projects"
          className={"text-center"}
          position={"center"}
          width={"171px"}
        />

        {CompletedProjectContent?.map((data, index) => (
          <div
            className={`row py-4 ${index % 2 === 0 ? "" : "flex-row-reverse"}`}
            key={index}
          >
            <div className="col-md-8">
              {data?.content?.map((value) => (
                <div className="d-flex">
                  <div className=" NewsIcon">
              <i class="fa fa-circle primary-color" aria-hidden="true" ></i>

                  </div>
                  <li className="projectList ls-none ">{value}</li>
                </div>
              ))}
            </div>
            <div className="col-md-3">
              <img src={data?.imgUrl} alt="img" className="img-fluid" />
            </div>
          </div>
        ))}

        <div className="row">
          {CompletedProjectList?.map((content, index) => (
            <div className="d-flex" key={index}>
              <div className=" NewsIcon">
              <i class="fa fa-circle primary-color" aria-hidden="true" ></i>

</div>
              <li className="projectList text-justify " style={{ listStyle:'none' }}>{content}</li>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionCompletedProjects;

