import React from "react";

function EmailLogo() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="14"
    fill="none"
    viewBox="0 0 19 14"
  >
    <path
      fill="#fff"
      d="M1 4.73c0-1.885 0-2.828.622-3.414C2.245.73 3.247.73 5.25.73h8.5c2.004 0 3.005 0 3.628.586C18 1.902 18 2.845 18 4.73v4c0 1.886 0 2.829-.622 3.415-.623.585-1.624.585-3.628.585h-8.5c-2.003 0-3.005 0-3.628-.585C1 11.559 1 10.616 1 8.73v-4z"
    ></path>
    <path
      stroke="#11467A"
      d="M1 3.73l7.55 3.553a2.244 2.244 0 001.9 0L18 3.73m-12.75 9h8.5c2.004 0 3.005 0 3.628-.585C18 11.559 18 10.616 18 8.73v-4c0-1.885 0-2.828-.622-3.414C16.755.73 15.754.73 13.75.73h-8.5c-2.003 0-3.005 0-3.628.586C1 1.902 1 2.845 1 4.73v4c0 1.886 0 2.829.622 3.415.623.585 1.625.585 3.628.585z"
    ></path>
  </svg>
  );
}

export default EmailLogo;