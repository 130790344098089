import React from "react";

function WhatsAppSendButton() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    viewBox="0 0 36 36"
  >
    <circle cx="18" cy="18" r="18" fill="#00B09C"></circle>
    <path
      fill="#FEFEFF"
      d="M25.728 18.554l-10.987-5.501a.498.498 0 00-.539.06.5.5 0 00-.165.5l1.324 4.886h5.648v1H15.36l-1.344 4.87a.5.5 0 00.5.63.5.5 0 00.224-.054l10.987-5.501a.5.5 0 000-.89z"
    ></path>
  </svg>
  );
}

export default WhatsAppSendButton;