import MainTitle from 'components/Common/MainTitle'
import React from 'react'
import COURSE_IMG from '../../assets/images/COURSE OVERVIEW.png'
import Certificate1 from '../../assets/images/Certificate1.png'
import Certificate2 from '../../assets/images/Certificate2.png'
function SectionServiceLayout7({content1, title}) {
const TrainingSection1 = [ 
    
{
    title: 'SAESPL conducts following IWCF-UK accredited courses at our training center in Ahmedabad:' ,
    TrainingSection1SubData :  [   {
    title : 'I. IWCF DRILLING WELL CONTROL PROGRAM - Level 2 (Introductory - 4 Days without Simulator)'
},

{
    title : 'II. IWCF DRILLING WELL CONTROL PROGRAM - LEVEL 3 (DRILLER Training- 5 Days)'
},
{
    title : 'III. IWCF DRILLING WELL CONTROL PROGRAM - LEVEL 4 (Supervisor Training - 5 Days)'
} ]
},
{
    title: 'SAESPL also conducts following IADC accredited courses at our training center in Ahmedabad.' ,
    TrainingSection1SubData :  [   {
    title : 'I. IADC WellSharp Introductory Level (3 Days without Simulator)'
},

{
    title : 'II. IADC WellSharp Fundamental/Driller Level (5 Days)'
},
{
    title : 'III. IADC WellSharp Supervisory Level (5 Days)'
} ]
}

]

    return (
        <>
       <div className='container'>
        <div className='row py-5 position-relative  '>
        <h1 className={`MainTitle `}  > Well Control and Safety Trainings  </h1>  
     <p className='border-title section-layout2-border-line-1'  ></p>
       {/* <MainTitle text={'Well Control and Safety Trainings'} /> */}
       
       {
           content1?.map((data)=>(<>
          <p className='fs20px fw500 lnh-34 text-justify mt-2'>
  {data?.contentData}
</p>

        
        </>))
       }
       </div>
       <div className='row  position-relative  '>
<div className='col-md-6 p-0'>
    {/* <MainTitle text={'COURSE OVERVIEW:'}/> */}
    <h1 className={`MainTitle `}  > COURSE OVERVIEW:  </h1>  
     <p className='border-title section-layout7-border-line-2'  ></p>
    <p className='fs20px fw500 lnh-34 text-justify'>
    IWCF well control courses (Level-3 and Level-4) are a five days program. First three days extensive classroom teaching is followed by practical sessions on simulator. On fourth day an in house mock test is conducted based on IWCF pattern. This is to access the performance of the participants and prepare them for the final test. The program consists of a Practical Assessment Exercise and Written Test papers on Equipment and principles & Procedures.
    </p>
</div>

<div className='col-md-6'>
<img src={COURSE_IMG} alt={title} className='img-fluid'/>
</div>


       </div>
   <div className='row '>
  
        
        <MainTitle text={'SAESPL Training Courses'} className='text-center pt-4'/>
        
      
        {
            TrainingSection1?.map((data)=>(<>
  <p className='fs24px primary-color fw500 pb-2 pt-2'> {data?.title}</p>
          {
            data?.TrainingSection1SubData?.map((data)=>(
                <>
              <div className='training-courses-btn'>

{                data?.title}
       </div>  
                </>
                
                ))
          }
            </>))
        }
           
          
   </div>
    <div className='d-flex justify-content-center py-4'>
        
<img src={Certificate1} alt={title} className='img-fluid mx-2'/>
<img src={Certificate2} alt={title} className='img-fluid mx-2'/>
        </div>  
     
     <div className='d-flex justify-content-center py-4'>
        <button className=' btn training-courses-contact-btn'>
        CONTACT US
        </button>
     </div>
        </div> 


        
        </>
    )
}

export default SectionServiceLayout7
