import React from 'react'

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M.701 13.872L13.872.702l1.78 1.778L2.48 15.651.701 13.872zm0-11.402L2.47.7 15.65 13.882l-1.768 1.77L.7 2.47z"
      ></path>
    </svg>
  );
}

export default CloseIcon;
