import React from 'react'
import MainTitle from '../Common/MainTitle'
import OurMission from '../../assets/images/OurMission.png'

function SectionOurVision() {
    return (
        <>
        <div className='container'>
  <div className='row py-5'>
    <div className='col-md-6'>
<MainTitle text={'Our Vision & Mission'} position={'start'} width={'80px'}/>
<p className='text-justify'>
<span  className='our-mission-heading'>Vision: </span>
 The company’s vision is to be a leading company in global energy sector providing Oil and Gas Services, City Gas Distribution Services through customer centricity, innovative approach and diversification, offering unparalleled services to our customers and excelling in the areas of our operations, keeping safety of people and asset and protection of environment in high priority.
</p>
<p className='text-justify'>
    <span  className='our-mission-heading'>
    Mission:
    </span>
  The company’s mission is to provide quality services to upstream, midstream and downstream Oil and Gas Industry from oilfield services to city gas distribution services, manpower consulting and training services at the most reasonable cost.
</p>

    </div>
    <div className='col-md-6'>
  <img src={OurMission} className='img-fluid' alt='OurMission'/>
    </div>
  </div>
        </div>
        </>
    )
}

export default SectionOurVision
