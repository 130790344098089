// import MainTitle from 'components/Common/MainTitle'
import React from 'react'

function SectionServiceLayout2({SectionTitle1 , SectionTitle1Content, SectionTitle2 , SectionTitle2Content }) {


    return (
       <>
       <div className='container'>
     <div className='row py-5 position-relative'>

     <h1 className={`MainTitle `}  > {SectionTitle1}  </h1>  
     <p className='border-title section-layout2-border-line-1'  ></p>
  <p className='fw500 lnh-35 fs20px'>
  {
    SectionTitle1Content
  }
  </p>
     </div>
     <div className='row pb-4 position-relative'>

  <h1 className={`MainTitle `}  > {SectionTitle2}  </h1>  
     <p className='border-title section-layout2-border-line-2'  ></p>
  <ul>
                    {SectionTitle2Content && SectionTitle2Content?.map((item) => (<>
                    <div className="d-flex">
                        <div className=" NewsIcon">
                        <i class="fa fa-circle" aria-hidden="true"></i></div>
                        <li className="newsList ">
                            {item.Title}   
                        </li>
                        </div>
                        </>
                    ))}
                </ul>
     </div>
       </div>
       
       </> 
    )
}

export default SectionServiceLayout2
