import MainTitle from 'components/Common/MainTitle'
import React from 'react'

function SectionWhyChooseSouthAsia() {
    return (
        <>
        <div className='container py-2'>
   <MainTitle text={'Why Choose South Asia Consultancy'} position={'start'} width={'171px'} />
   <p className='fw500  fs20px lnh-35'>
   We are master  and passionate about the work we do. We always come up with new ways to enrich your goal to greater heights. Extraordinary and High Quality services with safety. We have a supreme team who works rigorously to grow  businesses beyond client's expectations.
   </p>
        </div>
        </>
    )
}

export default SectionWhyChooseSouthAsia
