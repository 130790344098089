import React from "react";

function ServiceIcon1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="81"
      fill="none"
      viewBox="0 0 80 81"
    >
      <path
        stroke="#11467A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M37.263 56.623H10V18.5h50.934v10.963m-35.71 2.691h13.253m-13.252 14.9h11.178M16.323 27.67l3.49 4.635 4.704-6.927M16.323 42.62l3.49 4.634 4.704-6.927m18.58 17.492A15.79 15.79 0 0055.268 63.5C63.958 63.5 71 56.561 71 48.002c0-8.545-7.018-15.474-15.684-15.499M43.096 57.82a15.296 15.296 0 01-3.557-9.817c0-1.632.256-3.204.73-4.681m2.827 14.498l-.067.05m.067-.05L55.573 48.5m-.303-15.997h.046m0 0l-.047-2.89c-11.228-.449-16.523 8.289-17.956 12.708l2.956 1m15.047-10.818l.257 15.997m0 0l10.723 10.465M55.573 48.5l-15.304-5.18m0 0l.034-.102"
      ></path>
    </svg>
  );
}

export default ServiceIcon1;