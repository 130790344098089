import React from 'react'
import Logo from '../../assets/images/SouthAsiaLogo.png'

function SectionGroup() {
    return (
        <>
        <div className='SectionGroupAbout'>
            <div className='container py-4'>

<div className='d-flex justify-content-center '>
<h2 className='text-white'> Group Companies </h2>
</div>
<div className='row '>
<div className='col-md-6'>
    <div className='card-group-companies d-flex align-items-center p-4' >
  <img src={Logo} alt='logo' className='card-group-companies-img' />
  <h3 className='primary-color px-2 fs22px mt-3'>  South Asia Consultancy FZE  </h3>
    </div>
</div>
<div className='col-md-6'>
    <div className='card-group-companies d-flex align-items-center p-4' >
  <img src={Logo} alt='logo' className='card-group-companies-img' />
  <h3 className='primary-color px-2 fs22px mt-3'> South Asia Energy Services Pvt Ltd </h3>
    </div>
</div>
</div>
            </div>
        </div>
        </>
    )
}

export default SectionGroup
