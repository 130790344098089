import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import SectionHeroBanner from "../components/Common/SectionHeroBanner";
import RelatedProjects from "../components/ServiceInnerPage/RelatedProjects";
import { useParams } from "react-router-dom";
import WorkOurImg from "../assets/images/Workover.png";
import MaintenanceServiceImg from "../assets/images/MaintenanceService.png";
import MobileSteamingImg from "../assets/images/Mobile-Steaming Unit-Services.png";
import NitrogenTruck from "../assets/images/NitrogenTruck.png";
import MobilePumping from "../assets/images/Mobile Pumping Unit Services.png";
import MobileAir from "../assets/images/MobileAir.png";
import DrillingServices_IMG from "../assets/images/Drilling Services.png";
// import CrudOil from "../assets/images/CrudOil.png";
import DIESEL_IMG from "../assets/images/DueFuelEngine.png";
import GeneratorStore from "../assets/images/GeneratorState.png";
import CrudeOilTruckImg from "../assets/images/Crude Oil transportation Services .png"
import SectionServiceLayout1 from "components/ServiceInnerPage/SectionServiceLayout1";
import SectionServiceLayout2 from "components/ServiceInnerPage/SectionServiceLayout2";
import SectionWhyChooseSouthAsia from "components/ServiceInnerPage/SectionWhyChooseSouthAsia";
import SectionServiceLayout3 from "components/ServiceInnerPage/SectionServiceLayout3";
import SectionServiceLayout4 from "components/ServiceInnerPage/SectionServiceLayout4";
import SectionServiceLayout5 from "components/ServiceInnerPage/SectionServiceLayout5";
import SectionServiceLayout6 from "components/ServiceInnerPage/SectionServiceLayout6";
import SectionServiceLayout7 from "components/ServiceInnerPage/SectionServiceLayout7";

function ServiceInnerPage() {
  const ServiceInnerPageData = [
    {
      slug: "workover-rig-Services",
      title: "Workover & Completion Services",
      imgUrl: WorkOurImg,
      content1:
        "We are one of the leading service providers for Workover and Completion Services to various Oil and Gas companies in India. We provide complete rig package with all associated equipment and services to oil and gas industry. We provide wide range of Workover and Completion Rigs ranging from 150 HP to 1000 HP.",
      content2:
        "We also provide end to end solutions to our clients for Drilling and Workover Services. Our Services includes complete rig management, O&M, transportation and logistics services, Accommodation, Catering, Local Logistics support etc.",
      content3:
        "We have remarkably carried out and completed various workover and completion services contract for various Oil and Gas operators. We have a pool of oil and gas professionals having rich and varied experience in onshore and offshore Drilling and workover operations.",
      Layout: 1,
      CapableData : [ {
        title : 'Workover & Completion Services for HPHT wells.'  ,
       } ,
       {
         title : `Workover and completion Services with fast
         moving skidding rig.`  ,
        } , {
         title : 'Convention Workover Services.'  ,
        },{
         title : 'Intelligent Completion Services.'  ,
        }
     
     
     
     ]
    },
    {
      slug: "operation-maintenance-services",
      title: "Operation and Maintenance Services",
      imgUrl: MaintenanceServiceImg,
      content1:
        "We provide operation and maintenance services for Offshore Drilling Rigs, Onshore Drilling and Workover rigs, Oil and Gas installations, Sucker Rod Pump Units etc. We have a pool of multidisciplinary experts having rich and varied experience in Onshore and offshore drilling and workover operations, exploration and production activities, and plant maintenance etc.",
      content2:
        "We also provide end to end solutions to our clients for Drilling and Workover Services. Our Services includes complete rig management, O&M, transportation and logistics services, Accommodation, Catering, Local Logistics support etc.",
      content3:
        "Our employees are trained on technical and safety norms on regular basis. Safety of people and asset, protection of environment are our top priority while delivering excellence services.",
      Layout: 1,
      CapableData : [ {
        title : 'O&M for Onshore and offshore Drilling Rigs.'  ,
       } ,
       {
         title : `O&M for onshore and offshore completion
         and workover rigs.`  ,
        } , {
         title : 'O&M for Production Installations.'  ,
        },{
         title : 'O&M for SRP units.'  ,
        }
     
     
     
     ]
    },
    {
      slug: "oil-and-gas-exploration",
      title: "Oil and Gas Exploration",
      SectionTitle1: "Exploration and Production:",
      SectionTitle1Content:
        "South Asia Consultancy, primarily an Oil and Gas service provider, has diversified in the oil and gas Exploration and Production business to contribute to the energy security of our nation by exploring conventional and unconventional hydrocarbon resources through committed efforts ensuring safe and sustained growth, with the acquisition of South Patan Oil and Gas block through international competitive bidding in the Discovered Small Oil and Gas Field bidding round in 2016 by Government of India. With a portfolio of projects including several O&M of 6th generation Cyberbase control offshore rigs in Persian Gulf MENA region and many other countries, IWCF well control school in Ahmedabad etc. Oil & Gas vertical has proved itself as a strategic component of SAC’s philosophy of diversified growth.",
      SectionTitle2: "Oil & Gas Blocks- South Patan",
      SectionTitle2Content: [
        {
          Title:
            "South Patan block has been awarded to SAC by Govt. of India during marginal field DSF-2016 round. South Patan block covers an area of 9.88 sq.km. The block was explored by ONGC in the year 1989..",
        },
        {
          Title:
            "ONGC have drilled two wells during exploration phase named “SP-5” and “SP-7”. SP-5 was drilled in the year 1989 while SP-7 was in 1991 and both the wells have stuck oil. Thereafter the wells were temporarily abandoned.",
        },
        {
          Title:
            "South Patan Block is located in the South of Sanchor-Patan tectonic Block. Silt layer in the Cambay Shale was the main pay zone of these two wells. The actual age of the development of Hydrocarbon was Lower Eocene.",
        },
        {
          Title:
            "9.86 SKM 3D seismic survey data and 37.8 LKM 2D data proved the presence of Oil in the block. In both the wells potential hydrocarbon zone is encountered below 2000 m RKB.",
        },
      ],
      Layout: 2,
    },
    {
      slug: "nitrogen-pumping-unit-services",
      title: "Nitrogen Pumping Unit  Services",
      content1: [
        {
          contentData :  'We offer Nitrogen Pumping Unit services for various oilfield purposes. NPU is a hydraulic heat recovery Liquid Nitrogen Pump and vaporizer system. The liquid is pressurized by a triplex pump before being sent to the vaporizer. The high pressure liquid absorbs heat sufficient to raise the temperature to 70degrees Fahrenheit, converting the liquid to a gas state'
        } ,
        {
          contentData: 'Gas flows out the discharge valve at pressure and flow rate capable of 10,000 psi Max Working Pressure and 270,000 SCFM Max Flow Rate.'
        }
      ]
      
  ,
      Layout: 3,
      imgUrl: NitrogenTruck,
    },
    {
      slug: 'mobile-steaming-unit-services',
      title: 'Mobile Steaming Unit Services' ,
      content1 : 'We offer Mobile High Pressure Steaming Unit & Mobile Low Pressure steaming Unit suitable for well tube cleaning , de waxing, line flushing, steam injection in well with end to end service.' , 
      buttonText : [{
        title : 'Steam Temperature: 310 Degree Celsius,'
      } , 
      {
        title : 'Soft Water Tank Capacity: 10000 Liters,'
      },{
        title : 'Steam Discharge: 1500 kg/hr,'
      }
      ,{
        title : 'Steam Working Pressure: 100 kg/cm2'
      }
    
    
    ] , 
    imgUrl: MobileSteamingImg,
 Layout : 4
    },
    {
      slug: "mobile-pumping-unit-services",
      title: "Mobile Pumping Unit Services",
      content1: [{
        contentData: 'We offer Mobile Pumping unit services appropriate for pumping fluid like brine, water, oil effluent, etc. with discharge rate of 1540M3 /Hr & 40M3 /Hr and 200 & 400 KG/CM2 working pressure. The MPU can be used to stop any uncontrolled flow from wells, flushing of oil flow and to use before taking up hot jobs on flow lines, hydro testing of gas /oil flow line, lifting of spill oil , servicing of SRP well, and to use in assistance in pumping fluids for CTU & hot oil jobs whenever required. ' 
      }, {
        contentData: 'MPU Comprising a high-pressure horizontal, triplex, reciprocating plunger pump; transfer case from truck engine, gear pump for oil suction, storage tank, flexible joints & skid platform.'
      }
    
    
    ]
    ,
      Layout: 3,
      imgUrl: MobilePumping ,
    },
    {
      slug: "mobile-air-compressor-services",
      title: "Mobile Air Compressor Services ",
      content1:[ 
        
        {contentData: 'We provide Mobile Air Compressor services for various oilfield purposes including well offloading, well activation etc. Since 2014, we are providing Mobile Air compressor to top leading companies of oil and gas exploration and production in INDIA.'},
        {contentData: '"We have 02 nos of Mobile Air compressor of Kirlosker capacity of 120 Kg/cm2 & 180 Kg/cm2. Diesel engine Driven air compressor is horizontal, balanced opposed, reciprocating, of cross head, heavy duty, water cooled, double acting of a storage & Rated output 173 HP with 12 hours rating at 1800 rpm vertical water radiator cooled complete with electric starting equipment including battery and ammeter with standard inertia flywheel Forced lubrication of the rotating parts by means of a gear fitted with a pressure adjustable valve with oil filtering arrangement also Panel with air & oil pressure gauges.'},
     ]
   ,
      Layout: 3,
      Specification : true,
      imgUrl: MobileAir ,
      buttonText : [{
        title : 'Free air delivery - 6 M3/min. s'
      } , 
      {
        title : 'Working pressure - 120 kg/cm 2'
      },{
        title : 'Speed - 750 rpm'
      }
      ,{
        title : 'No. of Cylinders - 4'
      }
      ,{
        title : 'Bore - 1 X 230 mm'
      }
      ,{
        title : ' 1 X 125 mm, 1 X 85 mm,1 X 55 mm, Stroke - 150 mm'
      }
    
    
    ] 
    },
    {
      slug: "generator-sets",
      title: "DIESEL GENERATOR SET ",
   
      Layout: 5,
 
      imgUrl: GeneratorStore ,
      liText : [{
        title : 'We provide wide range of Diesel Generators on rental basis with excellent services. We offer 05KVA to 25KVA, 35KVA to 200 KVA and 250KVA, 320KVA, 380KVA, 500KVA, 600KVA, 750KVA, 1250KVA, 1875KVA and 2250KVA DG sets on rental.'
      } , 
      {
        title : 'We have remarkably carried out and successfully completed various contracts of different clients. We are currently providing 45KVA DG sets services to operate SRP units to Selan Exploration Technology Ltd.'
      },{
        title : `ENGINE: Heavy duty air cleaner, lube oil/coolant heater with thermostatic switch diesel Generator,
        Direct injection, water cooled engine, 12 cylinder, 4 strokes, rated at 1500 RPM. `
      }
      ,{
        title : `ALTERNATOR: Separately excited, self-regulated, Class ‘H’ Insulation, Salient pole revolving field, Single/
        double bearing, automatic voltage regulator PMG standard.`
      }
    ]  , 
    content1: [{
      title : `STANDARD CONTROL PANEL: Powder coated control panel with 14 / 16 gauge, Voltmeter and ammeter with selector switch, KW PF meters, Frequency meter, Aluminum bus bars of suitable capacity with incoming and outgoing terminations, Indicating lamps for “Load On” and “Set Running “Specification sheet 1000-1200 kWt,
      1250-1500 KVA Prime`
    } ,]
    },
    {
      slug: "duel-fuel-engines-services",
      title: "Duel Fuel Engines Services ",
     content1 : [{
      contentData : 'We provide Dual Fuel Engine to operate SRP units. Dual-Fuel Engine saves the cost of fuel by using produced gas (along with HSD) as fuel. We are providing Dual Fuel Engine Services to reputed Oil and Gas operator since many years. We have remarkably carried out and successfully completed various contracts of different operators.' , 
    
     } ,
    {
      contentData: 'We currently hold extensive range of Kirlosker make dual fuel engines of various capacities like 39 HP, 45 HP, 55 HP etc. & proficient manpower to provide uninterrupted 24x7 services to our client.'
    }
    
    ],
      Layout: 6,
       imgUrl: DIESEL_IMG ,
       buttonText : [{
        title : 'Engines make: Kirlosker'
      } , 
      {
        title : 'Fuel Type: Diesel and Gas'
      },{
        title : 'No. Of Cylinder : 03, 04'
      }
      ,{
        title : 'Cylinder Capacity: 3.3L 3-cyl Diesel'
      }
      ,{
        title : 'Engine Power : 45 HP'
      }
      ,{
        title : ' Cooling Type : Water cooled'
      }
      ,{
        title : ' Torque: 150 LB Ft'
      }
      ,{
        title : 'Max RPM: 1800'
      }
    
    
    ] 
   
   
    },
    {
      slug: "drilling-services",
      title: "Drilling Services",
      content1:[
        {
    contentData : 'We are one of the leading service providers for Drilling Services to various Oil and Gas companies in India, Middle East and Africa. We provide end to end solutions to our clients for Drilling and Workover Services. Our Services include complete rig management, O&M, transportation and logistics services, Accommodation, Catering, Local Logistics support etc.' ,
    
        },
        {
    contentData : 'We also provide complete rig package with all associated equipment and services to oil and gas industry. We provide our services for wide range of Drilling Rigs ranging from 750 HP to 3000 HP..' ,

        },
        {
    contentData : 'We have successfully managed operation and maintenance of offshore & onshore drilling rigs up to 3000 HP capacity, Amphion and Cyberbase control, and as latest as 7th generation rigs of reputed make like NOV, Drillmec, LeTourneau, Romania etc in Persian Gulf region.' ,

        }
      ] ,
      capable:true,
    
      Layout: 3,
      imgUrl: DrillingServices_IMG,
      CapableData : [ {
        title : 'Drilling Services for HPHT wells.'  ,
       } ,
       {
         title : `Drilling Services with fast moving skidding rig.`  ,
        } , {
         title : 'Convention Drilling Services.'  ,
        },{
         title : 'Integrated Drilling Services.'  ,
        }
     
     
     
     ]
    },
    {
      slug: "crude-oil-transportation-services",
      title: "Crude Oil transportation Services ",
      content1:[
        {
    contentData : 'We provide the best and safe transportation services of Crude Oil with our Crude Oil Tankers for Oil and Gas operators. We have very good track record of providing crude and water transportation services to our clients.' ,
    
        },
        {
    contentData : 'We have wide range of tankers of capacities 12KL, 20KL, 24KL and 29KL, of well known brands TATA & Ashok Leyland, with skilled & professional Drivers and Helpers to operate.' ,

        }
      ] ,

    
      Layout: 3,
      imgUrl: CrudeOilTruckImg,
     
     

    },
    {
      slug: "iWCF-iADC-and-other-safety-training",
      title: "IWCF-IADC and other safety Training",
      content1:[
        {
    contentData : 'South Asia Energy Services Pvt ltd, a vertical of SAC group of companies, is a premier training institute providing basic to advance level training of Safety and Well Control to Oil and Gas Field Personnel. SAESPL is accredited to IWCF to provide Drilling Well Control Courses – “Surface BOP Stack” & “Combined Surface & Subsea” from basic industry awareness to advance courses leading to improved operational and safety performance.' ,
    
        },
        {
    contentData : 'South Asia Energy Services Pvt ltd, a vertical of SAC group of companies, is a premier training institute providing basic to advance level training of Safety and Well Control to Oil and Gas Field Personnel. SAESPL is accredited to IWCF to provide Drilling Well Control Courses – “Surface BOP Stack” & “Combined Surface & Subsea” from basic industry awareness to advance courses leading to improved operational and safety performance.' ,

        },
        {
    contentData : 'SAESPL instructors are highly qualified and competent drilling personnel having more than 15 years of experience in practical drilling and well intervention services with excellent communication skills.' ,

        },
        {
    contentData : 'We provide best classroom training and simulator practice to our participants. Our compact batch, excellent teaching and quality course material make our programs a unique knowledge gaining experience.' ,

        }
      ] ,

    
      Layout: 7,
      imgUrl: CrudeOilTruckImg,
     
     

    },
  ];
  const { name } = useParams();

  const filteredData = ServiceInnerPageData.filter(
    (item) => item.slug === name
  );

  const {
    title,
    imgUrl,
    content1,
    content2,
    content3,
    Layout,
    SectionTitle1,
    SectionTitle1Content,
    SectionTitle2,
    SectionTitle2Content,
    Specification,
    buttonText,
    liText,
    capable,
    CapableData
  } = filteredData[0] ? filteredData[0] : [];

  const layoutComponents = {
    1: SectionServiceLayout1,
    2: SectionServiceLayout2,
    3: SectionServiceLayout3,
    4: SectionServiceLayout4,
    5: SectionServiceLayout5,
    6: SectionServiceLayout6,
    7: SectionServiceLayout7

  };

  const SelectedLayoutComponent = layoutComponents[Layout];

  return (
    <>
   <Fragment>
        <MetaTags>
          <title>SouthAsiaConsultancy | Services</title>
          <meta name="description" content="Organic Food React JS Template." />
        </MetaTags>
        <LayoutOne>
        {filteredData && filteredData[0] ? (
          <>
            <SectionHeroBanner
              title={title}
              className={"sectionServiceInnerBg"}
            />
            {SelectedLayoutComponent ? (
              <SelectedLayoutComponent
                imgUrl={imgUrl}
                content1={content1}
                content2={content2}
                content3={content3}
                SectionTitle1={SectionTitle1}
                SectionTitle1Content={SectionTitle1Content}
                SectionTitle2={SectionTitle2}
                SectionTitle2Content={SectionTitle2Content}
                buttonText = {buttonText}
                Specification = {Specification}
                liText={liText}
                capable= {capable}
                CapableData = {CapableData}
              />
            ) : null}
            <SectionWhyChooseSouthAsia />
            <RelatedProjects />{" "}
          </>
        ) : (
          <>
            <h1 className="text-center"> No Data Found </h1>
          </>
        )}
       </LayoutOne>
      </Fragment>
    </>
  );
}

export default ServiceInnerPage;
