import React from "react";
// import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import MobileMenu from "../components/mobile-menu/MobileMenu";
import MobileBtn from "../components/mobile-menu/MobileBtn";
// import SearchIcon from "../assets/svg/SearchIcon";
import { useMediaQuery } from "react-responsive";
import Logo from "../assets/images/SouthAsiaLogo.png";
// import SearchIconNew from '../assets/images/searchIcon.png'
import { Link } from "react-router-dom";

const Menu = () => {
  // const triggerSearch = () => {
  //   const offcanvasMobileMenu = document.querySelector(".search_icon_inr");
  //   offcanvasMobileMenu.classList.toggle("active");
  // };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  //   const location = useLocation();
  //   const currentPath = location.pathname;
  //  console.log(currentPath, 'current-path')
  return (
    <>
      {isMobile ? (
        <>
          <div className="menu_area">
            <div className="navigation">
              <div className="container">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={Logo}
                      alt="logo"
                      className="img-fluid Mobile-logo "
                      style={{ width: "48px", height: "49px" }}
                    />
                  </Link>
                </div>
                <p
                  className=" Mobile-Heading primary-color fw500"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  South Asia Consultancy{" "}
                </p>

                <MobileBtn />

                <MobileMenu />

                {/* <div className="header_cart">
                  <ul>
                    <li className="header_search">
                      <Link
                        to="#"
                        onClick={() => triggerSearch()}
                        className="cart-toggler search_icon"
                      >
                 
                        <img  src={SearchIconNew} className="img-fluid menu-icon-w" alt='searchIcon'/>
                      </Link>

                      <div className="search_icon_inr">
                        <form action="#" method="POST">
                          <div>
                            <input placeholder="Search" type="text" />
                            <button className="btn-search" type="submit">
                             
                        <img  src={SearchIconNew} className="img-fluid menu-icon-w" alt='searchIcon'/>

                            </button>
                          </div>
                        </form>
                      </div>
                    </li>
                   
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="nav-south-asia  menu-south-asia d-flex align-items-center">
            <div className="container ">
              <div className=" d-flex justify-content-center">
                <Navbar>
                  <Nav className="">
                    <ul className="d-flex">
                      <li className=" ">
                        <Link
                          // to={process.env.PUBLIC_URL + "/"}
                          to="/"
                          className={` menu-text-south `}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/about" className="menu-text-south ">
                          About us{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/services" className="menu-text-south ">
                          Services{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/project" className="menu-text-south ">
                          Project{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/gallery" className="menu-text-south ">
                          Gallery{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/careers" className="menu-text-south ">
                          Careers
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/contact" className="menu-text-south ">
                          Contact us{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/privacy-policy" className="menu-text-south ">
                          Policy{" "}
                        </Link>
                      </li>
                      <li className="active ">
                        <Link to="/terms-condition" className="menu-text-south ">
                          Terms {" "}
                        </Link>
                      </li>
                    </ul>
                  </Nav>
                  {/* <div className="ml-auto">
                    <SearchIcon />
                  </div> */}
                </Navbar>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Menu;
