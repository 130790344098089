// import AccordionMinusIcon from 'assets/svg/AccordionMinusIcon';
// import AccordionPlusIcon from 'assets/svg/AccordionPlusIcon'
import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'

function CareerAccordion({accordionData}) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

    const handleAccordionToggle = (index) => {
      setOpenAccordionIndex(index === openAccordionIndex ? null : index);
    };
    return (
        <>
      <div>
      {accordionData.map((item, index) => (
        <Accordion className='Career-accordion' key={index}  onClick={() => handleAccordionToggle(index)} activeKey={openAccordionIndex === index ? '0' : null}>
          <div className='w-full'>
            <p className='fs24px fw500'>
              {item.Title}
            </p>
          
          </div>

          <Accordion.Collapse eventKey="0">
            <div>

            <p>
              {item.description}
            </p>
            {/* <button className="CareerApply">Apply now</button> */}
            </div>

          </Accordion.Collapse>
        </Accordion>
      ))}
    </div>
 
        </>
    )
}

export default CareerAccordion
