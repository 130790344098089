import React from 'react'
import LayoutOne from '../layouts/LayoutOne'
import SectionHeroBanner from '../components/Common/SectionHeroBanner'
// import SectionOngoing from '../components/Project/SectionOngoing'
import SectionOngoingProject from 'components/Project/SectionOngoingProject'
import SectionCompletedProjects from 'components/Project/SectionCompletedProjects'

function Project() {
    return (
       <>
           <LayoutOne>

           <SectionHeroBanner title={'Project'} className={'sectionProjectBg'}/>
           {/* <SectionOngoing />
            */}
         <SectionOngoingProject/>
         <SectionCompletedProjects/>
           </LayoutOne>
       </> 
    )
}

export default Project
