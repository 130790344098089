import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/South-Asia-Logo.png";
import LocationLogo from "../assets/svg/LocationLogo";
import EmailLogo from "../assets/svg/EmailLogo";
import PhoneLogo from "../assets/svg/PhoneLogo";
import InstagramLogo from "../assets/svg/InstagramLogo";
import FaceBookLogo from "../assets/svg/FaceBookLogo";
import TwiiterLogo from "../assets/svg/TwiiterLogo";
import LinkedinLogo from "../assets/svg/LinkedinLogo";
import IFDCLogo from "../assets/images/IWCF-LOGO.png";
import IADCLogo from "../assets/images/IADC-LOGO.png";

const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="widget">
                <div className="footer_logo d-flex align-items-center">
                  <Link to="/" className="text-white">
                    <img className="img-responsive" src={Logo} alt="" />
                    <span className="mx-2 fs20px">South Asia Consultancy</span>
                  </Link>
                </div>
                <div className="footer_p">
                  <p className="footer_para text-white">
                    <Link to="/about" className="text-white">
                      South Asia Consultancy services have now expanded across a
                      wide range to suit our client’s requirement.
                    </Link>
                  </p>
                </div>
                <div className="footer_socil">
                  <ul className="list-icons link-list footer_soc">
                    <li>
                      <a
                        href="https://www.instagram.com/southasiaconsultancy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramLogo />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/SouthAsiaConsultancy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaceBookLogo />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/DSR_SOUTHASIA  "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TwiiterLogo />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.linkedin.com/company/southasiaconsultancy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedinLogo />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <ul className="list-unstyled">
                <h1 className="footer-heading">Contact Info</h1>
                <li className="d-flex gap-1 mb-2">
                  <div className="mt-2">
                    <a
                      href="https://www.google.com/maps?q=402, Orange Mall, Mehsana Highway, above HDFC Bank, Nigam Nagar, Chandkheda, Ahmedabad, Gujarat 382424"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LocationLogo />
                    </a>
                  </div>
                  <div className="mb-2 mt-2 mx-2">
                    <a
                      href={`https://www.google.com/maps?q=402, Orange Mall, Mehsana Highway, above HDFC Bank, Nigam Nagar, Chandkheda, Ahmedabad, Gujarat 382424`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      402, Orange Mall, Mehsana Highway, above HDFC Bank, Nigam
                      Nagar, Chandkheda, Ahmedabad, Gujarat 382424
                    </a>
                  </div>
                </li>
                <li className="d-flex gap-1 mb-2">
                  <div>
                    <PhoneLogo />
                  </div>
                  <div className="mx-2">
                    <a href="tel:+919316305258" className="text-white">
                      (+91) 9316305258{" "}
                    </a>
                  </div>
                </li>
                <li className="d-flex gap-1 mb-2">
                  <div>
                    <EmailLogo />
                  </div>
                  <div className="mb-2 mx-2">
                    <a
                      href="mailto:info@southasiaconsultancy.com"
                      className="text-white"
                    >
                      info@southasiaconsultancy.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-2  col-md-2 col-sm-12 ">
              <h1 className="footer-heading">Company</h1>
              <ul>
                <li>
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="text-white">
                    About us
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="text-white">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/project" className="text-white">
                    Project
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="text-white">
                    Gallery{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/careers" className="text-white">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-white">
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link to="/terms-condition" className="text-white">
                    Terms and Condition
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="text-white">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <img
                src={IFDCLogo}
                className="img-fluid py-2"
                alt="IFDC Logo"
                style={{ width: "100px" }}
              />
              <img
                src={IADCLogo}
                className="img-fluid py-2"
                alt="IADC Logo"
                style={{ width: "100px" }}
              />
            </div>
          </div>
        </div>

        <div className="subFooterSouth py-4">
          <p className="text-center">
            © {new Date().getFullYear()} All Rights reserved by South Asia
            Consultancy Services Pvt. Ltd.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
