import React from "react";
import MainTitle from "../Common/MainTitle";
import Card from "react-bootstrap/Card";
// import ProfilePic from "../../assets/images/ProfileImg.png";
import ClientImg1 from "../../assets/images/clientChirag.png";
import ClientImg2 from "../../assets/images/Client2.png";
import {Link} from "react-router-dom"
import ClientImg3 from "../../assets/images/Client3.png";
import client4 from "../../assets/images/Mshaikh.png"

function SectionCollaborate() {
  const CollaborateData = [
    {
      id: 1,
      ProfileImg: ClientImg1,
      Name: "Mr. Chirag Shah",
      content: `Chief Financial Officer`,
    },

    {
      id: 2,
      ProfileImg: ClientImg2,
      Name: "Manisha Singh Dhami",
      content: `Operations Manager `,
    },
    {
      id: 3,
      ProfileImg: ClientImg3,
      Name: "Adv. Mayank Shah",
      content: `Legal Advisor`,
    },
    {
      id: 4,
      ProfileImg: client4,
      Name: "Mr. M. A. Sheikh",
      content: `Vice President – Operations`,
    },
  ];
  const lineBreak = <br key="lineBreak" />;

  return (
    <>
      <div className="container"> 
        <MainTitle
          text={[
            "We Have Gathered the Finest ",
            lineBreak,
            "Team to Collaborate With You.",
          ]}
          className="sectionCollabrateText text-center"
          position={"center"}
          width={"108px"}
        />

        <div className="row">
          {CollaborateData?.map((data, index) => (
            <>
              <div className="col-md-3" key={index}>
                <Link to={"profile-details/" + data?.id}>
                <Card className="Collabrate-Card">
                  <div className="team_wrp_custom">
                    <div className="team_img">
                      <img src={data?.ProfileImg} alt="team" />
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title className="primary-color text-center">
                      {data?.Name}
                    </Card.Title>
                    <Card.Text className=" text-center">
                      {data?.content}
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Link>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionCollaborate;
