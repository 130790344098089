import React from 'react'

function MainSubHeading({text, className}) {
    return (
       <>
    <h2 className={`${className} MainSubHeading`}  > {text}  </h2>    
       
       </> 
    )
}

export default MainSubHeading
