import React from "react";

function LeftRedArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        stroke="maroon"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.182 3.5L12 7.5m0 0l-3.818 4M12 7.5H3"
      ></path>
    </svg>
  );
}

export default LeftRedArrow;