import React from "react";
// import MainTitle from "../components/Common/MainTitle";
import { useParams } from "react-router-dom";
import DrugFreePolicy from "../../src/assets/images/drugFree.png";
import LayoutOne from "layouts/LayoutOne";
import MainTitle from "components/Common/MainTitle";
const PolicyDetails = () => {
  const { name } = useParams();
  const CardData = [
    {
      name: "qhce-policy",
      Name: "QHSE Policy",
      Description: "",
      details: [
        {
          Title:
            "South Asia Consultancy (SAC) requires the active commitment to, and accountability for,QHSE from all employees and contractors. Line management has a leadership role in the communication and implementation of, and ensuring compliance with, QHSE policies and standards. We are committed to",
        },
        {
          Title:
            "Protect, and strive for improvement of, the health, safety and security of our people at all times",
        },
        {
          Title: "Eliminate Quality non-conformances and HSE accidents;",
        },
        {
          Title:
            "Meet specified customer requirements and ensure continuous customer satisfaction;",
        },
        {
          Title:
            "Set Quality & HSE performance objectives, measure results, assess and continually improve processes, services and product quality, through the use of an effective management system;",
        },
        {
          Title:
            "Plan for, respond to and recover from any emergency, crisis and business disruption;",
        },
        {
          Title:
            "Minimize our impact on the environment through pollution prevention, reduction of natural resource consumption and emissions, and the reduction and recycling of waste;",
        },
        {
          Title:
            "Apply our technical skills to all HSE aspects in the design and engineering of our services and products;",
        },
        {
          Title:
            "This Policy shall be regularly reviewed to ensure ongoing suitability. The commitments listed are in addition to our basic obligation to comply with SAC, as well as all applicable laws and regulations where we operate. This is critical to our business success because it allows us to systematically minimize all losses.",
        },
      ],
    },
    {
      name: "qaqc-policy",
      Name: "QAQC Policy",
      Description: "",
      details: [
        {
          Title:
            "Offshore Operations  & maintenance Services (Drilling & Marines)",
        },
        {
          Title:
            "Onshore Operations  & maintenance Services (Work-over & Drilling)",
        },
        {
          Title: "E&P Services",
        },
        {
          Title: "Integrated Services",
        },
        {
          Title: "Safety Training Services",
        },
        {
          Title: "Upstream & Downstream Manpower providing  Services",
        },
        {
          Title: "HR & Management Services",
        },
      ],
    },
    {
      name: "drug-and-alcohol-policy",
      Name: "Drug And Alcohol Policy",
      imgUrl: DrugFreePolicy,
      Description: "",
      details: [
        {
          Title:
            "The Drug And Alcohol Policy applies to everyone at SAC’s offices, sites, visitors and to all our personnel in the line of duty or any of our partner and staff in activities that we believe might be having a direct impact on the reputation and integrity of our company.",
        },
        {
          Title:
            "We understand the fact that drugs and alcohol use can affect a person’s ability to work safely. It creates a risk to workers and work health and safety.",
        },
        {
          Title:
            "Therefore, SAC always makes it clear that no one must drink alcohol or use drugs at workplace. However, they must notify their supervisors if prescribed medication is likely to affect their behavior and therefore work health and safety. Their supervisors may assign them other duties while they are taking the medication.",
        },
        {
          Title:
            "SAC does not allow or authorize the use of drugs and alcohol at workplace-based social events. The Safety managers/supervisors in our premises or designated worksite must, if they have reasonable grounds for believing that the worker is incapable of safely performing their duties or may be a risk to others due to the effects of drugs or alcohol, arrange for them to be removed safely from the workplace or site.",
        },

        {
          Title:
            "SAC makes sure that no one is, by the consumption of drugs or alcohol, in such a condition so as to endanger their own safety or that of others at the workplace or site. The strict prohibition that we issue as company committed to health and safety of our personnel and other parties include not allowing the person under influence of drugs and alcohol to come work if, after drinking or using drugs in their social time, their ability to work safely is still impaired.",
        },
        {
          Title:
            "Managing drugs and alcohol, SAC identifies all workplace site that may influence someone to turn to drugs or alcohol, and use the hazard management process to ith its entire staff and workers, partners, and the safety committee on this issue.",
        },
        {
          Title:
            "If anyone is found to breach our policy, the management will always ensure the adherence to the due process as stipulated in the Human Resources policy and Procedures. Responsible social events can be held within our premises. And to ensure everyone remains safe, we always expect that everyone must act responsibly during such social events and that alcohol is strictly forbidden and not allowed in our premises.",
        },
        {
          Title:
            "SAC is hereby committed to providing regular training and information about the effects of drug and alcohol use on personal and public health and safety",
        },
      ],
    },
    {
      name: "ppe-policy",
      Name: "PPE Policy",
      Description: "",
      details: [
        {
          Title: `Introduction:The purpose of the Personal Protective Equipment Policies is to protect the employees of South Asia Consultancy from exposure to work place hazards and the risk of injury through theuse of personal protective equipment (PPE).  PPE is not a substitute for more effective controlmethods and its use will be considered only when other means of protection against hazards arenot adequate or feasible. It will be used in conjunction with other controls unless no other meansof hazard control exist.Personal protective equipment will be provided, used, and maintained when it has been determined that its use is required to ensure the safety and health of our employees and that suchuse will lessen the likelihood of occupational injury and/or illness.This section addresses general PPE requirements, including eye and face, head, foot and leg, hand and arm, body (torso) protection, and protection from drowning. Separate programs existfor respiratory protection and hearing protection as the need for participation in these programs isestablished through industrial hygiene monitoring.`,
        },

        {
          Title:
            "Our purpose is to contribute in vital process of harness Oil & natural energy resources for global benefits. ",
        },
      ],
    },
  ];
  const FilterFeatureData = CardData.filter((item) => item.name === name);

  return (
    <>
      <LayoutOne>
        <div className="container pt-4">
          <div className="d-flex justify-content-center">
            <MainTitle text={FilterFeatureData[0].Name} />
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              {FilterFeatureData.length > 0 &&
                FilterFeatureData.map((item) => (
                  <>
                    <div className="d-flex justify-content-center">
                      {item?.imgUrl && (
                        <img src={item.imgUrl} alt="clients" width={500} />
                      )}
                    </div>
                    <p className="mt-4 ">{item.Description}</p>{" "}
                  </>
                ))}
              <ul>
                {FilterFeatureData[0].details.map((item, index) => (
                  <>
                    <div key={index} className="d-flex">
                      <div className=" NewsIcon">
                        <i class="fa fa-circle" aria-hidden="true"></i>
                      </div>
                      <li className="newsList ">{item.Title}</li>
                    </div>
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </LayoutOne>
    </>
  );
};

export default PolicyDetails;
