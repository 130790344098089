import React from 'react'

function InputLabel({text}) {
    return (
        <>
      <p className='custom-lable'>
        {text}
        </p>  
        
        </>
    )
}

export default InputLabel
