import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import EmailIcon from "../../assets/images/Icon_Email.png";
import Address from "../../assets/images/Icon_Address.png";
import PhoneIcon from "../../assets/images/Icon_Phone.png";
import CloseIcon from "../../assets/images/closeIcon.png";

const MobileMenu = () => {
  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(`.subMenu`);
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        `<span class="menuExpand"><i></i></span>`
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menuExpand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", e => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  });

  const sideMenuExpand = e => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="offcanvasMobileMenu" id="offcanvas-mobile-menu">
      <button
        className="offcanvasMenuClose"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
      {/* <i className="icon-glyph-146"></i> */}
      {/* <i class="fa fa-close"></i> */}
      <img src={CloseIcon} className="img-fluid" alt="img"/>
      </button>

      <div className="offcanvasWrapper">
        <div className="offcanvasInnerContent">

          <nav className="offcanvasNavigation" id="offcanvas-navigation">
            <ul>
              <li>
                <Link to= "/">Home</Link>
              </li>
              <li>
                <Link to= "/about">
About Us</Link>
              </li>
              <li>
                <Link to= "/services">       
                Services

</Link>
              </li>
         
              <li>
                <Link to= "/project">       
                Project

</Link>
              </li>
              <li>
                <Link to= "/gallery">       
                Gallery

</Link>
              </li>
              <li>
                <Link to= "/careers">       
                Careers

</Link>
              </li>
              <li>
                <Link to= "/contact">       
            
Contact Us

</Link>
              </li>
          
           


            </ul>
          </nav>

          {/* Search Form */}
          {/* <div className="offcanvasMobileSearchArea">
            <form action="#">
              <input type="search" placeholder="Search ..." />
              <button type="submit"> 
              </button>
            </form>
          </div> */}

          {/* Contact Info  */} 

          <div className="header_top_right list-unstyled">
            <ul>
              <li className="d-flex align-items-center ">
              {/* <i className="fa fa-phone"></i>  */}
              <img src={PhoneIcon} alt="" className="img-fluid" />
              <a href="tel:+919316305258" className="mx-2">+91 9316305258</a>
              </li>
              <li className="d-flex align-items-center ">
              <img src={EmailIcon} alt="" className="img-fluid" />

              
              <a href="mailto:info@southasiaconsultancy.com" className="mx-2">
                        info@southasiaconsultancy.com
                      </a>
              </li>
              <li className="d-flex align-items-center ">
              <img src={Address} alt="" className="img-fluid" />
              <a
                      href={`https://www.google.com/maps?q=402, Orange Mall, Mehsana Highway, above HDFC Bank, Nigam Nagar, Chandkheda, Ahmedabad, Gujarat 382424`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-1 text-justify"
                    >
              402, Orange Mall, Chandkheda, Ahmedabad, Gujarat 382424
                      </a>
       

        
              </li>
            </ul>
          </div>

      

        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
