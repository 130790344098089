import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./servicesAction";

export const escrowSlice = createSlice({
  name: "escrow",
  initialState: {
    getBranchData: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetBranchData.pending, (state) => {
        state.isLoadingBranch = true;
      })
      .addCase(actions.GetBranchData.fulfilled, (state, action) => {
        state.getBranchData = action.payload?.getBranchData;
        state.isLoadingBranch = false;
      })
      .addCase(actions.GetBranchData.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoadingBranch = false;
      });
  },
});
export const { reset } = escrowSlice.actions;

export default escrowSlice.reducer;
