import React from "react";

function FaceBookLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#fff"
        d="M35.06 12.567c3.047 9.308-2.073 19.336-11.382 22.34-9.308 3.047-19.336-2.073-22.34-11.382C-1.71 14.217 3.41 4.19 12.719 1.185A17.41 17.41 0 0118.22.296c7.658.042 14.47 4.993 16.84 12.27zM18.22 1.947C9.292 1.946 2.1 9.181 2.1 18.11c0 8.927 7.234 16.12 16.162 16.12 8.928 0 16.121-7.235 16.121-16.163a15.17 15.17 0 00-.804-4.95C31.421 6.43 25.201 1.904 18.22 1.947z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21.097 11.974h1.989V8.97c-.296 0-.635-.085-.973-.085h-1.777c-.889 0-1.777.254-2.497.762a3.762 3.762 0 00-1.48 2.496c-.085.381-.128.804-.128 1.185v2.37h-2.877v3.342h2.835v8.42h3.554v-8.462h2.835l.339-3.343h-3.3v-2.37c.126-1.014.719-1.31 1.48-1.31z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FaceBookLogo;