import React from "react";
// import MainTitle from "../Common/MainTitle";
// import Card from "react-bootstrap/Card";
// import SectionBusiness1 from "../../assets/images/SectionBusiness1.png";
// import SectionBusiness2 from "../../assets/images/SectionBusiness2.png";
// import CardImg1 from "../../assets/images/BusinessNewsCard.png";
import GalleryImg1 from "../../assets/images/Gallary1.webp";
import GalleryImg2 from "../../assets/images/Gallary2.webp";
import GalleryImg3 from "../../assets/images/Gallary3.webp";
import GalleryImg4 from "../../assets/images/Gallary4.webp";
import GalleryImg5 from "../../assets/images/Gallary5.webp";
import GalleryImg6 from "../../assets/images/Gallary6.webp";
import Team from "../../assets/images/Team.webp";

const SectionBusiness = () => {
  // const CardData = [
  //   {
  //     CardImg: CardImg1,
  //     Title:
  //       "South Asia Consultancy’s Field Development Plans Get DGH approval.",
  //       Subtitle : "April 19, 2018"
  //   },
  //   {
  //     CardImg: CardImg1,
  //     Title:
  //       "South Asia Consultancy Plans to bid for oil blocks in OALP round",
  //       Subtitle:"June 24, 2017"
  //   },
  //   {
  //     CardImg: CardImg1,
  //     Title:
  //       "India to get first oil from small field round in four months from South Asia Consultancy’s Field South Patan.",
  //       Subtitle : "April 05, 2017"
  //   },
  //   {
  //     CardImg: CardImg1,
  //     Title:
  //       "South Asia Consultancy, bagged a block in DSF round urges Govt. to incentivize measures for enhanced oil recovery.",
  //       Subtitle : "April 04, 2017"
  //   },
  //   {
  //     CardImg: CardImg1,
  //     Title:
  //       "South Asia Consultancy bet on pricing freedom, assured in new contract regime RSC for small field.",
  //       Subtitle:  "February 17, 2017"
  //   },
  //   {
  //     CardImg: CardImg1,
  //     Title:
  //       "Cabinet awards 31 oil blocks to 22 firms; South Asia Consultancy bagged South Patan oil block in Cambay basin, Gujarat.",
  //       Subtitle: "February 16, 2017"
  //   },
  // ];
  return (
    <>
      <div className="container pt-4">
      
   
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 text-white">
            <img src={GalleryImg1} alt="sectionBusinss" />
            {/* <p className="BusinessNews">
              Lorem ipsum dolor sit amet consectetur. <br></br>Dignissim aliquam
              mattis quam
            </p> */}
            {/* <p className="BusinessNewsTime">December 19, 2023</p> */}
          </div>
          <div className="col-md-6">
            <div className=" text-white">
              <img src={GalleryImg2} alt="sectionBusinss" />
      
            </div>
            <div className=" text-white mt-4">
              <img src={GalleryImg3} alt="sectionBusinss" />
            
            </div>
          </div>
        </div>
        <div className="row py-4" >
    <div className="col-md-4">
    <img src={GalleryImg4} alt="gallery" />

    </div>
    <div className="col-md-4">
    <img src={GalleryImg5} alt="gallery" />

    </div>
    <div className="col-md-4">
    <img src={GalleryImg6} alt="gallery" />

    </div>
        </div>
        <div className="row  py-4">
          <div className="container">

  <img src={Team} className="img-fluid w-full" style={{ width:'1090px' }}  alt='team'/>
          </div>
        </div>
        {/* <div className="row py-4">
          {CardData?.map((card, index) => (
            <div className="col-md-4 mt-2" key={index}>
              <Card>
                <Card.Img
                  variant="top"
                  src={card?.CardImg}
                  className=""
                  
                />
                <Card.Body className="px-2 py-3 text-black">
                  <Card.Title className=" fs14 fw700 h55 lnh-24 ">
                    {card?.Title}
                  </Card.Title>
                  <Card.Subtitle className="fs12">
                    {card.Subtitle}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default SectionBusiness;
