import React from "react";

function InstagramLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#fff"
        d="M17.729.1C7.955.1 0 8.055 0 17.83s7.955 17.728 17.729 17.728 17.728-7.954 17.728-17.728C35.457 8.055 27.503.1 17.73.1zm0 34.103c-9.055 0-16.375-7.32-16.333-16.374 0-9.055 7.32-16.375 16.375-16.333 9.055 0 16.375 7.32 16.332 16.375 0 2.327-.507 4.612-1.438 6.685a16.4 16.4 0 01-14.936 9.647z"
      ></path>
      <path
        fill="#fff"
        d="M23.187 8.224H12.27C10.029 8.224 8.21 10 8.166 12.2v11.213a4.027 4.027 0 004.104 3.977h10.875c2.242.043 4.062-1.777 4.104-3.977V12.2c-.042-2.2-1.862-3.977-4.062-3.977zm2.412 15.232a2.366 2.366 0 01-2.37 2.37H12.271a2.367 2.367 0 01-2.455-2.285V12.244a2.366 2.366 0 012.37-2.37H23.145a2.367 2.367 0 012.454 2.285v11.297z"
      ></path>
      <path
        fill="#fff"
        d="M17.729 12.836a5.002 5.002 0 00-4.993 4.993 5.002 5.002 0 004.993 4.993 5.002 5.002 0 004.992-4.993 5.002 5.002 0 00-4.992-4.993zm0 8.293a3.323 3.323 0 01-3.343-3.343 3.323 3.323 0 013.343-3.342 3.323 3.323 0 013.342 3.342v.043a3.35 3.35 0 01-3.342 3.342v-.042zM23.06 13.81a1.185 1.185 0 100-2.37 1.185 1.185 0 000 2.37z"
      ></path>
    </svg>
  );
}

export default InstagramLogo;