import React from "react";
import InputLabel from "../Common/InputLable";
import MainTitle from "components/Common/MainTitle";
import MainSubHeading from "components/Common/MainSubHeading";

function SectionContactUs() {
  return (
    <>
      <div className="container pt-4 pb-4">
        <div className="row">
        <div className="col-md-8">
        <MainTitle text={'Daily News Update'} width={'121px'} position={'start'}/>

        <MainSubHeading text="Agency of the Year 2023 shortlist: Southeast Asia" />
            <p className=" font-weight-bold text-black">
              South Asia Consultancy Services have a strong commitment to
              providing quality services and building long-lasting relationships
              with their clients. We focus on customer satisfaction and
              relationship building can be an attractive quality for companies
              who value reliable and trustworthy partners.
            </p>
            <p className=" ">
              South Asia Consultancy Services is expertise in the drilling and
              marine business can be an asset for companies in the oil and gas
              industry who may face complex challenges and require solutions
              from experienced professionals. The ability to pre-empt problems
              and provide solutions can be valuable in reducing downtime and
              maximizing efficiency.
            </p>
          </div>
          <div className="col-md-4">
            <div>
              <form>
                <InputLabel text="First Name" />
                <input
                  type="text"
                  placeholder="First Name"
                  className="custom-input-element mb-2"
                />
                <InputLabel text="Last Name" />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="custom-input-element mb-2"
                />
                <InputLabel text="Mobile Number" />
                <input
                  type="text"
                  placeholder="Mobile Number"
                  className="custom-input-element mb-2"
                />
                <InputLabel text="Email" />
                <input
                  type="text"
                  placeholder="Email"
                  className="custom-input-element mb-2"
                />
                <div className="d-flex justify-content-center">
                  <button className=" btn   Contact-us-submit-btn mb-4 mt-1">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
       
        </div>
      </div>
    </>
  );
}

export default SectionContactUs;
