import React from "react";

function ServiceIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="81"
      fill="none"
      viewBox="0 0 80 81"
    >
      <path
        stroke="#11467A"
        strokeWidth="2"
        d="M42.594 54.397C35.43 54.397 29.623 48.4 29.623 41c0-7.4 5.807-13.397 12.971-13.397m0 26.794V41m0 13.397V69.5h-4.01v-7.712a20.055 20.055 0 01-7.391-3.166l-5.344 5.519-5.67-5.856 5.345-5.521a21.459 21.459 0 01-3.057-7.623H15v-8.282h7.467a21.459 21.459 0 013.057-7.623l-5.219-5.39 5.67-5.856 5.218 5.388a20.055 20.055 0 017.392-3.166V12.5h4.01v15.103m0 0V41m0 0h13.923M42.83 27.846h9.434l7.311-7.551M42.358 54.154h9.434l7.312 7.551m4.01-20.949c0 1.884-1.48 3.41-3.303 3.41s-3.302-1.526-3.302-3.41c0-1.883 1.479-3.41 3.302-3.41 1.824 0 3.302 1.527 3.302 3.41zM65 18.346c0 1.884-1.478 3.41-3.302 3.41s-3.302-1.526-3.302-3.41c0-1.883 1.478-3.41 3.302-3.41S65 16.463 65 18.346zm-.472 45.308c0-1.884-1.478-3.41-3.302-3.41-1.823 0-3.301 1.526-3.301 3.41 0 1.883 1.478 3.41 3.301 3.41 1.824 0 3.302-1.527 3.302-3.41z"
      ></path>
    </svg>
  );
}

export default ServiceIcon2;