import MainTitle from 'components/Common/MainTitle'
// import ServiceButton from 'components/Common/ServiceButton'
import React from 'react'
import SectionCapable from './SectionCapable'

function SectionServiceLayout3({content1,CapableData ,capable, imgUrl , Specification, buttonText}) {
    return (
        <>
       <div className='container'>
        <div className='row py-5'>
          {
            content1?.map((data)=>(<>
             <p className='fw500 fs20px lnh-35 text-black'>
  {data?.contentData}
   </p>
            
            </>))
          }
  
       {
        capable === true && <>
        <SectionCapable CapableData = {CapableData }/>
        </>
       }   

{
    Specification === true && <>
    <div className='py-2'>

    <MainTitle text={'Specification'}/>
    <div className='row'>
{
  buttonText?.map((data)=>(<>
  <div className='col-md-4'>


<button className='specification-btn'>
 {data?.title}
</button>
  </div>
  
  </>))
}
  </div>
    </div>
    
    </>
}

   <img
   src={imgUrl}
   className='img-fluid'
   alt='img'
   />
        </div>
        </div> 
        </>
    )
}

export default SectionServiceLayout3
