import React from "react";
import MainTitle from "../Common/MainTitle";
import {Link} from 'react-router-dom'; 
function SectionQuality() {
  const QualityContent = [
    {
      title: `
    Protect, and strive for improvement of the health, safety and security of our people at all times
    `,
    },
    {
      title: `
    Eliminate Quality non-conformances and HSE accidents;
    `,
    },
    {
      title: `
    Meet specified customer requirements and ensure continuous customer satisfaction;
    `,
    },
    {
      title: `
    Set Quality & HSE performance objectives, measure results, assess and continually improve processes, services and product quality, through the use of an effective management system;
    `,
    },
    {
      title: `
    PIan for, respond to and recover from any emergency, crisis and business disruption;
    `,
    },
    {
      title: `
    Minimize our impact on the environment through pollution prevention, reduction of natural resource consumption and emissions, and the reduction and recycling of waste;
    `,
    },
    {
      title: `
    Apply our technical skills to all HSE aspects in the design and engineering of our services and products.
    `,
    },
  ];
  const PolicyData = [
    {
      id: 1,
      Name: "QHSE Policy",
      name: "qhce-policy"
    },

    {
      id: 2,
      Name: "QAQC Policy",
      name: "qaqc-policy"
    },
    {
      id: 3,
      Name: "Drug And Alcohol Policy",
      name: "drug-and-alcohol-policy"
    },
    {
      id: 4,
      Name: "PPE Policy",
      name: "ppe-policy"
    },
  ];
  return (
    <>
      <div className="container">
        <div>
          <MainTitle
            text={"Quality, Health, Safety & Environment"}
            position={"start"}
            width={"80px"}
          />
          <p className="our-mission-text">
            SAC believes business sustainability is dependent on continuous
            improvement of service quality while protecting people, asset and
            environment. Health & Safety of People, Operational safety, Quality
            improvement, protection of environment is our top priority while
            delivering excellent service.
          </p>
          <p className="our-mission-text">
            We abide by laws pertaining to oil and gas exploration and
            production in India, particularly the Directorate General of Mines
            Safety (DGMS) and Oil Industry Safety Directorate (OISD). All the
            stipulations are monitored and timely reported to the respective
            regulatory agencies.
          </p>
          <p className="our-mission-text">
            Our aim is to achieve no harm and no leaks across all our
            operations. We train our employees on technical safety norms on
            regular basis.
          </p>
          <p className="our-mission-text">
            SAC management is committed to promote Safe work environment and
            transparent system for its workforce. This is demonstrated by
            establishing the policies.
          </p>
          <div className="row">
            {/* <div className="col-md-6"> */}

            {PolicyData.map((item) => (<Link to={"/policy-details/" +item.name}>              <div className="col-md-4">
              <button className="btn our-mission-button">{item.Name}</button>

            </div></Link>
            ))}
            {/* </div> */}
          </div>
        </div>

        <div>
          <MainTitle
            text={"Quality, Health, Safety & Environment"}
            position={"start"}
            width={"80px"}
          />
          <div>
            <ul>
              {QualityContent &&
                QualityContent?.map((item, index) => (
                  <>
                    <div className="d-flex" key={index}>
                      <div className=" NewsIcon">
                        <i class="fa fa-circle" aria-hidden="true"></i>
                      </div>
                      <li className="newsList ">{item.title}</li>
                    </div>
                  </>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionQuality;
