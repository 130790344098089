import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/SouthAsiaLogo.png";
import EmailIcon from "../assets/images/Icon_Email.png";
import Address from "../assets/images/Icon_Address.png";
import PhoneIcon from "../assets/images/Icon_Phone.png";
const Header = () => {
  return (
    <>
      <div className="header_topbar">
        <div className="container">
          <div className="    ">
            <div className="row  header-south-asia ">
              <div className="col-md-6   header-logo-margin ">
                <div className="d-flex  align-items-center  ">
                  <Link to="/">
                    <img src={Logo} alt="" className="img-fluid" />
                  </Link>
                  <Link to="/">
                    <p className="header-logo-text  mt-1">
                      South Asia Consultancy
                    </p>
                  </Link>
                </div>
              </div>

              <div className="col-md-6  ">
                <div className=" row d-flex header-contact-icon">
                  <div className="col-md-2 d-flex align-items-start justify-content-end ">
                    <img src={EmailIcon} alt="" className="mt-1 " />
                    <p className="header-contact-text mx-1">
                      <a href="mailto:info@southasiaconsultancy.com">
                        info@southasiaconsultancy.com
                      </a>
                    </p>
                  </div>
                  <div className="col-md-6   d-flex align-items-start justify-content-end ">
                    <img src={Address} alt="" className="mx-1" />
                    <p className="header-contact-text ">
                      402, Orange Mall, Chandkheda, Ahmedabad, Gujarat 382424
                    </p>
                  </div>
                  <div className="col-md-4 d-flex align-items-start justify-content-end ">
                    <img src={PhoneIcon} alt="" className="" />
                    <p className="header-contact-text  ">
                      {" "}
                      <a href="tel:+919316305258">+91 9316305258</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
