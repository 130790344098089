import React from "react";
import MainTitle from "../Common/MainTitle";
// import BottomBorder from "../../assets/svg/BottamBorder";
import Card from "react-bootstrap/Card";
import ServiceIcon1 from "../../assets/svg/ServiceIcon1";
import ServiceIcon2 from "../../assets/svg/ServiceIcon2";
import ServiceIcon3 from "../../assets/svg/ServiceIcon3";
import ReadMoreButton from "components/Common/ReadMoreButton";
import { Link } from "react-router-dom";
  
function SectionServiceSlider() {
  const ServiceCardData = [
    {
        Title: 'Workover & Completion Services' , 
        Text: `We are one of the leading service providers for Workover and Completion Services to various Oil and Gas companies in India. We provide complete rig package with all associated equipment and services to oil and gas industry. ` , 
        Icon : <ServiceIcon1/>,
        link: "/service/workover-rig-Services",
        } , 
    {
        Title: 'Operation and Maintenance Services' , 
        Text: `We provide operation and maintenance services for Offshore Drilling Rigs, Onshore Drilling and Workover rigs, Oil and Gas installations, Sucker Rod Pump Units etc. 
        ` , 
        Icon : <ServiceIcon2/>,
        link :"/service/operation-maintenance-services",
        } , 
    {
        Title: 'Oil and Gas Exploration' , 
        Text: ` South Asia specializes in providing integrated logistics solutions to the oil & gas sector. Building on our specialist industry expertise, we provide a comprehensive range of services 
        ` , 
        Icon : <ServiceIcon3/>,
        link:"/service/oil-and-gas-exploration",
        } , 

  ]  


 
  return (
    <>
    <div className="Section-service-bg py-2">
   <div className="container  ">
        <MainTitle text={"Service"} className="text-center" position={'center'} width={'80px'} />
        <div className="d-flex justify-content-center">
          {/* <BottomBorder /> */}
        </div>
   
       <div className="row mt-2">
       {
  ServiceCardData?.map((card, index) => (
    <div className="col-md-4" key={index}>
      <Card className="ServiceCard">
        <Card.Body>
          {card?.Icon}
          <Card.Title className="ServiceCardTitle">{card?.Title}</Card.Title>
          <Card.Text className="ServiceCardText">{card?.Text}</Card.Text>
          <Link to={card?.link}>
         <ReadMoreButton/>
         </Link>
        </Card.Body>
      </Card>
    </div>
  ))
}
     
        </div>          

                  
              
          
    
        
     
          
    
      </div>
      </div>
    </>
  );
}

export default SectionServiceSlider;
