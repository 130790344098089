import React from 'react'

function SliderLeftArrow() {
    return (
        <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.880645 16.9905L14.8806 30.9901C15.4273 31.5368 16.3138 31.5368 16.8605 30.99C17.4072 30.4433 17.4072 29.5569 16.8604 29.0101L3.85042 16.0004L16.8605 2.99C17.4072 2.44325 17.4072 1.55687 16.8604 1.01012C16.5872 0.736748 16.2289 0.600107 15.8705 0.600107C15.5122 0.600107 15.1539 0.736748 14.8806 1.01022L0.880645 15.0106C0.618005 15.2731 0.470539 15.6292 0.470539 16.0005C0.470538 16.3718 0.618005 16.7279 0.880645 16.9905Z" fill="#11467A"/>
</svg>

    )
}

export default SliderLeftArrow
