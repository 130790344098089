import React from "react";

function TwiiterLogo() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.7511 9H26.8178L20.1179 16.2011L28 26H21.8282L16.9946 20.0569L11.4636 26H8.39488L15.5611 18.2977L8 9H14.3281L18.6975 14.4321L23.7511 9ZM22.6747 24.2738H24.3741L13.4048 10.6356H11.5814L22.6747 24.2738Z" fill="white"/>
    <path d="M17.7287 0C7.95464 0 0 7.95464 0 17.7287C0 27.5027 7.95464 35.4574 17.7287 35.4574C27.5027 35.4574 35.4574 27.5027 35.4574 17.7287C35.4574 7.95464 27.5027 0 17.7287 0ZM17.7287 34.1034C8.67394 34.1034 1.35398 26.7834 1.39629 17.7287C1.39629 8.67394 8.71625 1.35398 17.771 1.39629C26.8258 1.39629 34.1457 8.71626 34.1034 17.771C34.1034 20.0982 33.5957 22.383 32.6648 24.4563C29.9991 30.3376 24.1601 34.1034 17.7287 34.1034Z" fill="white"/>
    </svg>
    
  );
}

export default TwiiterLogo;