import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import SectionHeroBanner from "components/Common/SectionHeroBanner";
import SectionCareer from "components/Career/SectionCareer";
import SectionJobApply from "components/Career/SectioJobApply";
// import SectionJobRuleCard from "components/Career/SectionJobRuleCard";

const Careers = () => {
 
    return(
        <Fragment>
            <MetaTags>
              <title> SouthAsiaConsultanct | Careers</title>
              <meta
                name="description"
                content="Organic Food React JS Template."
              />
            </MetaTags>
            <LayoutOne>
            <SectionHeroBanner title={'We Believe Anything is Possible'} description={'SAC is an Oilfield Service Provider as well as upstream oil and gas Exploration and Production Company headquartered in India.'} className={'sectionAboutBg'}/>
            <SectionCareer />
            <SectionJobApply />
            {/* <SectionJobRuleCard /> */}
            </LayoutOne>
        </Fragment>
            
    ); 
};

export default Careers;   