import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const accessToken = localStorage.getItem("accessToken");

export const GetBranchData = createAsyncThunk(
  "escrow/getBranchData",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `https://dummyjson.com/products`
        // const response = await axios.get(
        //   `${baseurl.paymentBaseURL}${escrowApiEndPoint.GetBranchData}`

        // {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        // }
      );
      return { getBranchData: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
