import React from "react";

function SectionHeroBanner({ title, subtitle, className, description }) {
  return (
    <>
      <div className={`${className}`}>
        <div className="container">
          <div className="col-md-7">
          <h1 className="BannerTitle">{title}</h1>
          </div>
          <h5 className="BannerSubTitle">{subtitle}</h5>
          <div className="col-md-6">
          <p className="text-white">{description}</p>

          </div>
        </div>
      </div>
    </>
  );
}

export default SectionHeroBanner;
