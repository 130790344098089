import React from "react";
// import Card from "react-bootstrap/Card"
// import CareerJob from "../../assets/images/CareerJob.png"
import CareerAccordion from "./CareerAccordion";
import MainTitle from "components/Common/MainTitle";

const SectionCareer = () => {
  const CardData = [
    {
      CardNo:"01",
      Title:
        "Instrumentation Technician",
        city:"Ahmedabad",
        description: `Matriculation& ITI/ Diploma/degree in instrumentation, instrumentation & controlsystem, Electronics or equivalent with 3 year
        experiencefor ITI, two &one year experience in case of diploma/ degree holder. Out oftotal experience 01 year at rig instrumentation for
        ITIand 06 months in case of degree/diploma qualification for drilling rig.`
    },
    {
        CardNo:"02",
        Title:
          "Mechanical Maintenance Engineer",
          city:"Ahmedabad",
          description: `Degree in Mechanical Engg.with three year experience, out of which 01 year as maintenance In-charge or 02years master
          mechanic on drilling rig. ORDiploma in Mechanical Engg. with minimum 07 years’ experience, out of which 02year as Mechanical In-charge /03 years as master mechanic on drilling rig .`
    },
    {
        CardNo:"03",
        Title:
          "Mechanical Technician",
          city:"Ahmedabad",
          description:`Diplomain Mechanical / Automobile with two years’ experience (out of which at leastsix months as Mechanic at drilling rig) OR Matric with ITI in mechanical trade/Fitter / Diesel Mechanic / Auto Mechanic
          /Welding with four years’ experience (out of which atleast one year as Mechanicat drilling rig)`
    },
    {
        CardNo:"04",
        Title:
          "Electrical Technician/ Electrician",
          city:"Ahmedabad",
          description: `Degree in Electrical Engg.with one year experience on drilling rig and should possess trade certificateand should also passes a valid
          certificate of competency(supervisory license) issued by the electricity licensing board/ inspectorate .OR Diploma in Electrical Engg. with minimum 04 years’ experience, out of which01 year as electrical supervisor on drilling rig and should possess tradecertificate and should also possess the valid certificate of competency(supervisory license) issued by the electricity licensing board / inspectorate.OR ITI in electrical trade with four years’ experience out of which one yearexperience on drilling rig and having valid certificate of competency(Supervisory License) issued by Electricity Licensing Board / inspector.`
      },
    {
        CardNo:"05",
        Title:
          "Welder",
          city:"Ahmedabad",
          description: `Matriculation & ITI inwelding trade with three year experience, out of which minimum 01 year asdrilling rig welder .`
      },
  ];


  return (
    <>
    <div>
      <div className="container pt-4">
        {/* <div className="row py-4 d-flex justify-content-center">
          {CardData && CardData?.map((card, index) => (
            <div className="col-md-10 mt-2" key={index}>
              <Card className="p-4">
                <Card.Body>
                    <div className="d-flex ">
                      <div className="row">
                        <div className="col-md-3">
                        <div>
                            <h1 className="CareerNo mt-4 ">
                                {card?.CardNo}
                            </h1>
                        </div>
                        </div>
                        <div className="col-md-9">
                        <div>
                            
                            <Card.Title className="SectionAboutCardTitle">
                            <h3 className="CardTitle"> {card?.Title}</h3> 
                            </Card.Title>
                            <div className="d-flex">
                              <button className="CareerButtonTime">
                                Full Time  
                              </button>
                              <h3 className="mx-2">
                                  {card?.city}
                              </h3>
                            </div>
                            <Card.Text className="CareerCardDescription">
                              {card?.description}
                            </Card.Text>
                                  <button className="CareerApply">Apply now</button>
                                  </div>
                        </div>
                      </div>
                       
                     
                    </div>
                </Card.Body>

              </Card>
            </div>
          ))}
        </div> */}
        <div className="row" >
     
          <div className="col-md-12">
   <MainTitle text={'Open Positions'} width={'121px'} position={'start'}/>
      <CareerAccordion accordionData={CardData}/>
          </div>
        </div>

   
      </div>
    
      </div>
    </>
  );
};

export default SectionCareer;
