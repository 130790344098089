import React from "react";
// import LeftRedArrow from '../../assets/svg/LeftRedArrow'
import MainTitle from "../Common/MainTitle";
import MainSubHeading from "../Common/MainSubHeading";
import { Link } from "react-router-dom";
import FeatureImg from "../../assets/images/Features.webp";
import { useMediaQuery } from "react-responsive";

function SectionFeatures() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const FeatureData = [
    {
      id: 1,
      Name: "Relationship Building",
      name: "relationship-building",
    },

    {
      id: 2,
      Name: "Industry Expertise",
      name: "industry-expertise",
    },
    {
      id: 3,
      Name: "Clients Satisfaction",
      name: "clients-satisfaction",
    },
  ];
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <MainTitle
              text={"Features"}
              position={"justify-content-start"}
              width={"80px"}
            />
            <MainSubHeading text="The advantage of working with South Asia Consultancy" />
            <p className=" font-weight-bold text-black">
              South Asia Consultancy Services have a strong commitment to
              providing quality services and building long-lasting relationships
              with their clients. We focus on customer satisfaction and
              relationship building can be an attractive quality for companies
              who value reliable and trustworthy partners.
            </p>
            <p className=" ">
              South Asia Consultancy Services is expertise in the drilling and
              marine business can be an asset for companies in the oil and gas
              industry who may face complex challenges and require solutions
              from experienced professionals. The ability to pre-empt problems
              and provide solutions can be valuable in reducing downtime and
              maximizing efficiency.
            </p>
          </div>
          <div className="col-md-6">
            <img src={FeatureImg} className="img-fluid" alt="aboutUS" />
          </div>
        </div>
        <div className="row  ">
          <div className={isMobile ? "" : "d-flex"}>
            {FeatureData.map((item) => (
              <div className="col-md-4">
                <Link to={"/features-details/" + item.name}>
                  <button className="btn features-btn ">{item.Name}</button>
                </Link>
              </div>
            ))}
            {/* <div className="col-md-4">
                <button className="btn features-btn ">
                Clients satisfaction
                </button>
              </div>
              <div className="col-md-4">
                <button className="btn features-btn ">
                  Industry Expertise
                </button>
              </div>
              <div className="col-md-4">
                <button className="btn features-btn ">
                  Customer satisfaction
                </button>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionFeatures;
