import React, { useEffect, useRef, useState } from "react"
import SliderImg1 from "../../assets/images/Slider1.png"
import SliderImg2 from "../../assets/images/Silder2.png"
import SliderImg3 from "../../assets/images/Silder3.png"
import SliderImg4 from "../../assets/images/Gallary4.png"
import SliderImg5 from "../../assets/images/Gallary5.png"
// import SliderImg6 from "../../assets/images/Silder6.webp"
import SliderImg7 from "../../assets/images/Slider7.webp"
import SliderImg8 from "../../assets/images/Silder8.webp"
// import SliderImg9 from "../../assets/images/Silder9.webp"
import SliderImg10 from "../../assets/images/Silder10.webp"
import SliderLeftArrow from "assets/svg/SliderLeftArrow"
import SliderRightArrow from "assets/svg/SilderRightArrow"
import MainTitle from "components/Common/MainTitle"
import { useMediaQuery } from "react-responsive"

// import './styles.css'
function SectionSliderGallery() {
  const SliderData = [
    { imgUrl: SliderImg1 },
    { imgUrl: SliderImg2 },
    { imgUrl: SliderImg3 },
    { imgUrl: SliderImg4 },
    { imgUrl: SliderImg5 },
    // { imgUrl: SliderImg6 },
    { imgUrl: SliderImg7 },
    { imgUrl: SliderImg8 },
    // { imgUrl: SliderImg9 },
    { imgUrl: SliderImg10 },
  ]

  const [currentIndex, setCurrentIndex] = useState(0)
  console.log(SliderData?.length - 1, "ss")
  console.log(currentIndex, "curr")
  const handleLeftArrowClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + SliderData.length) % SliderData.length
    )
  }

  const handleRightArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % SliderData.length)
  }

  const prevIndexRef = useRef()

  useEffect(() => {
    prevIndexRef.current = currentIndex
  }, [currentIndex])
  
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
    {
      isMobile ?  <>  <div className="container">
      <MainTitle text="Business Standard News" className="text-center" />
      <div className="row   position-relative">
        <div className="col-md-12 ">
          {SliderData[currentIndex]?.imgUrl && (
            <div className="d-flex justify-content-center">
              <img
                src={SliderData[currentIndex]?.imgUrl}
                alt="img"
                className="img-fluid slider-img-url-1"
              />
            </div>
          )}
        </div>
      

       

        <button
          className=" btn slider-left-arrow"
          onClick={handleLeftArrowClick}
        >
          <SliderLeftArrow />
        </button>
        <button
          className=" btn slider-right-arrow"
          onClick={handleRightArrowClick}
        >
          <SliderRightArrow />
        </button>
      </div>
    </div>  </>  : <>  <div className="container mt-4">
        <MainTitle text="Business Standard News" className="text-center" />
        <div className="row  slider-main-div position-relative">
          <div className="col-md-3">
            {SliderData[currentIndex]?.imgUrl && (
              <div className="slider-img-1">
                <img
                  src={SliderData[currentIndex]?.imgUrl}
                  alt="img"
                  className="img-fluid slider-img-url-1"
                />
              </div>
            )}
          </div>
          {SliderData?.length - 1 === currentIndex ? (
            <>
              {" "}
              <div className="col-md-6">
                {
               
                  <div
                    className={`slider-img-2 ${
                      currentIndex + 1 !== prevIndexRef.current ? "fade-in" : ""
                    }`}
                  >
                    <img
                      src={SliderData[0]?.imgUrl}
                      alt="img"
                      className="img-fluid  slider-img-url-2"
                    />
                  </div>
                }
              </div>{" "}
            </>
          ) : (
            <>
              {" "}
              <div className="col-md-6">
                {SliderData[currentIndex + 1]?.imgUrl && (
                  <div
                    className={`slider-img-2 ${
                      currentIndex + 1 !== prevIndexRef.current ? "fade-in" : ""
                    }`}
                  >
                    <img
                      src={SliderData[currentIndex + 1]?.imgUrl}
                      alt="img"
                      className="img-fluid  slider-img-url-2"
                    />
                  </div>
                )}
              </div>{" "}
            </>
          )}

          {SliderData?.length - 1 === currentIndex ? (
            <>
              {" "}
              <div className="col-md-3">
                {
                
                  <div className="slider-img-3">
                    <img
                      src={SliderData[1]?.imgUrl}
                      alt="img"
                      className="img-fluid  slider-img-url-1"
                    />
                  </div>
                }
              </div>{" "}
            </>
          ) : (
            <>
              {" "}
              <div className="col-md-3">
                {SliderData[currentIndex + 2]?.imgUrl && (
                  <div className="slider-img-3">
                    <img
                      src={SliderData[currentIndex + 2]?.imgUrl}
                      alt="img"
                      className="img-fluid  slider-img-url-1"
                    />
                  </div>
                )}
              </div>{" "}
            </>
          )}

          <button
            className=" btn slider-left-arrow"
            onClick={handleLeftArrowClick}
          >
            <SliderLeftArrow />
          </button>
          <button
            className=" btn slider-right-arrow"
            onClick={handleRightArrowClick}
          >
            <SliderRightArrow />
          </button>
        </div>
      </div>  </>
    }
     
    </>
  )
}

export default SectionSliderGallery
