import React from 'react'

function SectionServiceLayout6({content1, title, imgUrl, buttonText }) {
    return (
        <>
        <div className='container'>
  <div className='row py-4'>
 <div className='col-md-6'>
    {
        content1?.map((data)=>(<>
        
        <p className='fw500 fs20px lnh-34 text-justify'>
{
    data?.contentData
}
</p>
        
        </>))
    }

 </div>
 <div className='col-md-6'>
<img src={imgUrl} className='img-fluid' alt={title} />
 </div>

 <p className='primary-color fw500 fs20px lnh-35 container py-2'>
 We are currently providing Dual Fuel Engine Services to Selan Exploration Technology to operate SRP unit at Bakrol, Lohar and Karjasan Field.
 </p>
 <div  className='row'>

 {
     buttonText?.map((data)=>(<>
  <div className='col-md-4'>


<button className='specification-btn'>
 {data?.title}
</button>
  </div>
  
  </>))
}
        </div>


  </div>
        </div>
        
        
        </>
    )
}

export default SectionServiceLayout6
