import React from 'react'
import MainTitle from '../Common/MainTitle'
import BottomBorder from '../../assets/svg/BottamBorder'
import ActivityImg from '../../assets/images/ActivityImg.png'
import ActivitySubImg1 from '../../assets/images/ActivitySub1.png'
import ActivitySubImg2 from '../../assets/images/ActivitySub2.png'
import ActivitySubImg3 from '../../assets/images/ActivitySub3.png'
// import ActivitySubImg4 from '../../assets/images/ActivitySub4.png'

import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; 
// import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/css';
// import 'swiper/css/free-mode';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';

function SectionActivity() {
  const images = [
    {
      original: ActivityImg,
      thumbnail: ActivityImg,
      // description: 'Image 1 Description',
    },
    {
      original: ActivitySubImg1,
      thumbnail: ActivitySubImg1,
      // description: 'Image 2 Description',
    },
    {
      original: ActivitySubImg2,
      thumbnail: ActivitySubImg2,
      // description: 'Image 2 Description',
    },
    {
      original: ActivitySubImg3,
      thumbnail: ActivitySubImg3,
      // description: 'Image 2 Description',
    }
 
  ];
    // const [mainImage, setMainImage] = useState(ActivityImg);

    // const handleSubImageClick = (newImageSrc) => {
    //   setMainImage(newImageSrc);
    // };
    return (
     <>
     
     <div className='container py-4'>
 <MainTitle text={'Activities'} className={'text-center'} position={'center'}   />
     <div className='d-flex justify-content-center'>
 <BottomBorder/>
     </div>
     {/* <div className='d-flex justify-content-center'>

<img src={mainImage} className='img-fluid py-2' alt='img' style={{ width:'883px' , height:'455px' }} />
     </div> */}
     
       <div className='row py-2'>
        
      <Gallery items={images}  />
     
      </div>
     </div>
     </>   
    )
}

export default SectionActivity
