import React from "react";
import MainTitle from "../Common/MainTitle";

const SectionNews = () => {
  const CardData = [
    {
      Title:
        "SAC’s contract for Crude Transportation Services to transport Selan’s crude from Bakrol, Lohar and Karjasan Field has been extended by Selan for 1 Year w.e.f from July 01, 2020.",
    },
    {
      Title:
        "Standard TOR has been issued to South Asia Consultancy by MoEFCC vide no. No.IA-J-11011/84/2020-IA-II (I) on 29th April, 2020 for the purpose of preparing environment impact assessment report and environment management plan for obtaining prior environment clearance for re-access and re-activation of temporarily abandoned wells of South Patan block.",
    },
    {
      Title:
      "SAC has been awarded contract for providing 40 KVA DG set for SRP operations at Bakrol Field of Selan Exploration Technologies Ltd on 22th June, 2019."
    },
    {
      Title: "South Asia Consultancy joins hands with Quippo Oil and Gas Infrastructure Limited for deployment of 750 HP Workover Rig for Onshore Operations-Rajsthan for Block RJ-ON-90/1 of Cairn Oil and Gas (Vedanta Ltd) on 18th September, 2018.",
    },
    {
      Title:
        "SAC has been awarded contract for providing Mobile Air Compressor Services at Selan’s Bakrol, Lohar, Karjasan and Indora Field, on 04th April, 2018.",
    },
    {
      Title:
        "FDP has been approved by DGH on 27th March, 2018.",
    },

    {
      Title:
        "South Patan PML has been transferred to South Asia Consultancy on 8th August, 2017.",
    },

    {
      Title:
        "SAC has been awarded contract for providing Crude Oil Transpiration Service by Oil Tankers to transport Selan’s Crude oil from Bakrol, Lohar, Karjasan and Indora Field, on 15th April, 2017.",
    },
    {
      Title:
        "SAC has been awarded contract for providing Dual-Fuel Engines to operate SRP at Selan’s Bakrol, Lohar, Karjasan and Indora Field, on 1st April, 2017.",
    },

    {
      Title:
        "South Asia Consultancy has been awarded South Patan Oil Block in Cambay Basin, Gujarat on 17th March, 2017 by Govt. of India in Discovered Small Field Bidding Round 2016.",
    },
    {
      Title:
        "South Asia Consultancy have submitted bid for Oil Blocks auctioned by Ministry of Petroleum and Natural Gas, Govt. of India on 21st November, 2016.",
    },
    {
      Title:
        "South Asia Consultancy has been awarded Contract for Operation and Maintenance of 75 Ton Workover Rig for 3 Years by Joshi Technology International Inc. on 1st July, 2016.",
    },
  ];
  

  return (
    <>
      <div className="container pt-4">
        <div className="d-flex justify-content-center">
          <MainTitle text="News" />
        </div>
        <div className="row d-flex justify-content-center"> 
            <div className="col-md-12">
                <ul>
                    {CardData && CardData.map((item) => (<>
                    <div className="d-flex">
                        <div className=" NewsIcon">
                        <i class="fa fa-circle" aria-hidden="true"></i></div>
                        <li className="newsList ">
                            {item.Title}   
                        </li>
                        </div>
                        </>
                    ))}
                </ul>

            </div>
        </div>

      </div>
    </>
  );
};

export default SectionNews;
