import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
// import Slides from '../components/Slides';
// import AboutContent from '../components/AboutContent';
// import ServiceContent from '../components/ServiceContent';
// import Funfact from '../components/Funfact';
// import WorkProcess from '../components/WorkProcess';
// import ChooseUs from '../components/ChooseUs';
// import ProductsList from '../components/ProductsList';
// import PricingList from '../components/PricingList';
// import GalleryContent from '../components/GalleryContent';
// import Team from '../components/Team';
// import Testimonial from '../components/Testimonial';
// import BlogList from '../components/BlogList';
// import ClinetCarousel from '../components/ClinetCarousel';
// import ContactForm from '../components/ContactForm';
import SectionHero from "../components/Home/SectionHero";
import SectionServiceSlider from "../components/Home/SectionServiceSlider";
import SectionAboutUs from "../components/Home/SectionAboutUs";
import SectionFeatures from "../components/Home/SectionFeatures";
import SectionWorldWide from "../components/Home/SectionWorldWide";
import SectionPartner from "../components/Home/SectionPartner";
import SectionContactUs from "../components/Home/SectionContactUs";
import SectionGroupAwards from "components/About/SectionGroupAwards";
import { useDispatch, useSelector } from "react-redux";
import { GetBranchData } from "redux/service/servicesAction";

const Home = () => {
  const dispatch = useDispatch();
  const isState = useSelector((state) => state);
  console.log(isState, "products");
  useEffect(() => {
    dispatch(GetBranchData());
  }, []);
  return (
    <Fragment>
      <MetaTags>
        <title>SouthAsiaConsultancy</title>
        <meta name="description" content="Organic Food React JS Template." />
      </MetaTags>
      <LayoutOne>
        <SectionHero />
        <SectionServiceSlider />
        <SectionAboutUs />
        <SectionFeatures />
        <SectionGroupAwards />
        <SectionWorldWide />
        <SectionPartner />
        <SectionContactUs />

        {/* <Slides /> */}
        {/* <AboutContent /> */}
        {/* <ServiceContent /> */}
        {/* <Funfact /> */}
        {/* <WorkProcess /> */}
        {/* <ChooseUs />  */}
        {/* <ProductsList /> */}
        {/* <PricingList />  */}
        {/* <GalleryContent /> */}
        {/* <Team />  */}
        {/* <Testimonial /> */}
        {/* <BlogList />  */}
        {/* <ClinetCarousel /> */}
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
