import React from "react";
import MainTitle from "../components/Common/MainTitle";
import { useParams } from "react-router-dom";
import ClientSatisfaction from "../../src/assets/images/clientSatisfaction.png";
import RelationBuilding from "../../src/assets/images/relatioBuilding.png";
import IndustryExpertise from "../../src/assets/images/industryExpertise.png";
import LayoutOne from 'layouts/LayoutOne'
const FeaturesDetails = () => {
  const { name } = useParams();
  const CardData = [
    {
      name: "relationship-building",
      Name: "Relationship Building",
      imgUrl: ClientSatisfaction,
      Description:
        "",
      details: [
        {
          Title:
            "SAC believes that open and frequent communication is essential to build a strong connection with clients. ",
        },
        {
          Title:
            "Effective communication helps to establish trust and understanding between the oil and gas industry and its suppliers, promotes collaboration, and improves the delivery of services.",
        }
      ],
    },
    {
      name: "industry-expertise",
      Name: "Industry Expertise",
      imgUrl: IndustryExpertise,
      Description:
        "In last over Fifteen years South Asia consultancy known as a one of the largest O&M services providers of Oil & Gas industry which makes us different from others. Our continuous learning , Setting the Goals , Develop the technical Experts team made us experts in many areas.",
      details: [
        {
          Title:
            "Offshore Operations  & maintenance Services (Drilling & Marines)",
        },
        {
          Title:
            "Onshore Operations  & maintenance Services (Work-over & Drilling)",
        },
        {
          Title: "E&P Services",
        },
        {
          Title: "Integrated Services",
        },
        {
          Title: "Safety Training Services",
        },
        {
          Title: "Upstream & Downstream Manpower providing  Services",
        },
        {
          Title: "HR & Management Services",
        },
      ],
    },
    {
      name: "clients-satisfaction",
      Name: "Clients Satisfaction",
      imgUrl: RelationBuilding,
      Description:
        "",
      details: [
        {
          Title:
            "SAC focused to find out clients value, always listen to what they say and adjusting our approach to match their expectations.  ",
        },
        {
          Title:
            "SAC creates solutions that enhance streamline operations and sustain uptime to meet or exceed the targets of Oil & Gas producers by providing integrated solution across well stages. It’s our core responsibility to give innovative and superior services to our clients.             ",
        },
        {
          Title:
            "Our purpose is to contribute in vital process of harness Oil & natural energy resources for global benefits. ",
        },
        {
          Title:
            "Our systematic working methods to deliver the services within timeline , Quality assurance, reliability , Competitive prices are the reason behind clients belief & interest to work with us.",
        },
        
      ],
    },
  ];
  const FilterFeatureData = CardData.filter((item) => item.name === name);
  return (
    <>
      <LayoutOne>
      <div className="container pt-4">
        <div className="d-flex justify-content-center">
          <MainTitle text={FilterFeatureData[0].Name} />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            {FilterFeatureData.length > 0 &&
              FilterFeatureData.map((item) => (
                <>
                <div className="d-flex justify-content-center">

                <img src={item.imgUrl} alt="clients" />
                </div>
                  <p className="mt-4 ">{item.Description}</p>{" "}
                </>
              ))}
            <ul>
              {FilterFeatureData[0].details.map((item, index) => (
                <>
                  <div key={index} className="d-flex m-0">
                    <div className=" NewsIcon">
                      <p className="fs20px">{index + 1}</p>
                    </div>
                    <li className="newsList ">{item.Title}</li>
                  </div>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
      </LayoutOne>
    </>
  );
};

export default FeaturesDetails;
