import React from 'react'
import InputLabel from "../Common/InputLable";

const ServiceQuestion = () => {
  return (
    <div>
          <div className="container pt-4 pb-4">
        <div className="row">
          <div className="col-md-12 p-0">
            <div>
              <form>
                <div className='d-flex'>
                  <div className='row'>

                <div className='col-md-6 '>
                <InputLabel text="Name" />
                <input
                  type="text"
                  placeholder="Enter your full name"
                  className="custom-input-element-service mb-2"
                />
                </div>
                <div className='col-md-6'>
                <InputLabel text="Mobile Number" />
                <input
                  type="text"
                  placeholder="Enter your Mobile Number"
                  className="custom-input-element-service  mb-2"
                />
                </div>
                </div>

                </div>
             
                
               
                <InputLabel text="Comment" />
                <textarea
                  type="text"
                  placeholder="Your Message Here"
                  rows="4"
                  className=" mb-2 service-input-textarea"
                />
                <div className="d-flex justify-content-center">
                  <button className=" btn   Contact-us-submit-btn-service">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  )
}

export default ServiceQuestion
