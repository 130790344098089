import React from 'react'
import MainTitle from '../Common/MainTitle'
import AwardImg from '../../assets/images/Awards.png'

function SectionAwards() {
    return (
        <>
     <div className='container'>
        <div className='row'>
   <div className='col-md-7'>
 <MainTitle text={'Awards and Accreditation'} position={'start'} width={'80px'}/>
 <p className='our-mission-text text-justify'> 
 ISO 9001:2015 certified by TNV Certification, accredited by Dubai Accreditation Center (DAC) for compliance with Quality Management System for Providing Services of Drilling and Workover in the Oil and Gas Sector.
 </p>
 <p className='our-mission-text text-justify'> 
 IWCF primary member accredited by International Well Control Forum for Drilling Well Control Programmed – Level 3 and Level 4.
 </p>
 <p className='our-mission-text text-justify'> 
 Best Rig of the Quarter (July-Sept 2019) award for proactive participation and commitment in improving HSE and Operational performance awarded by Cairn Oil and Gas to SAC crew deployed at onshore completion and work over rig Q-37.
 </p>
   </div>
   <div className='col-md-5 d-flex justify-content-end'>
<img src={AwardImg} className='img-fluid' alt='AwardImg'/>
   </div>
        </div>
        </div>   
        
        </>
    )
}

export default SectionAwards
