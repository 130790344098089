import React from "react";

function LocationLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="23"
      fill="none"
      viewBox="0 0 18 23"
    >
      <path
        fill="#F2F2F5"
        d="M7.788 22.327C1.22 12.994 0 12.037 0 8.607 0 3.909 3.886.1 8.68.1c4.795 0 8.681 3.809 8.681 8.507 0 3.43-1.22 4.388-7.788 13.72-.431.61-1.354.61-1.785 0zm.893-10.176c1.997 0 3.617-1.587 3.617-3.544 0-1.958-1.62-3.545-3.617-3.545-1.998 0-3.617 1.587-3.617 3.545 0 1.957 1.619 3.544 3.617 3.544z"
      ></path>
    </svg>
  );
}

export default LocationLogo;