import React from "react";
import ClientImg from "../../assets/images/ClientImg.png";
import BottomBorder from "../../assets/svg/BottamBorder";
import MainTitle from "../Common/MainTitle";

function SectionClient() {
  return (
    <>
      <div className="pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={ClientImg} className="img-fluid" alt="Client-img" />
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <BottomBorder className="" />
                <p className="mt-2 mx-2 fs24px text-black ">Management </p>
              </div>
              <MainTitle text="Mr. D S Rajput" />
              <p className="text-justify">
                The company is headed by efficient, able and futuristic leader.
                Mr. D.S. Rajput, aged 43 years, is the Managing Director of the
                company. He has an MBA in International Business, Trade and Tax
                Law and a B.Tech degree in Mechanical Engineering. He is a
                petroleum scholar, eminent professor, dynamic leader and a
                social worker. He has been serving the industry for more than
                twelve years with a diversified international exposure. He
                taught and trained so many professionals in different petroleum
                universities. Presently he is heading the South Asia group and
                under his dynamic leadership the group has reached a new height.
                He is the main backbone and pillar of the whole group which is
                globally doing various operations in upstream Oil and Gas
                sector. He has experience in managing different types of latest
                6th Generation drilling and work over rigs in both onshore and
                offshore fields. He also serves as Assessor for IWCF well
                Control School of Group Company South Asia Energy Services Pvt
                Ltd. He has contested for Chairperson of IWCF MENA Region. He
                also holds the position of Treasurer at Association of
                Discovered Small Field Operators.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionClient;
