import React from 'react'
import MainTitle from '../Common/MainTitle'
import MainSubHeading from '../Common/MainSubHeading'
import WorldWide  from '../../assets/images/WorldWideWeb.webp'

function SectionWorldWide() {
    return (
    <>
    <div className='container py-4 '>
    <div>
        <MainTitle text='World Wide' className='text-center' position={'center'} width={'80px'} />
        <MainSubHeading text='Trained Personnel in our workforce' className='text-center'/>
        <div className=''>
     <img src={WorldWide} alt='WorldWide' />
        </div>
    </div>
    </div>
    </>    
    )
}

export default SectionWorldWide
