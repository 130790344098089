import React , {Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import SectionNews from '../components/Gallery/SectionNews';
import SectionHeroBanner from '../components/Common/SectionHeroBanner';
import SectionBusiness from "../components/Gallery/SectionBusiness"
import SectionSliderGallery from 'components/Gallery/SectionSlider';
class Gallery extends Component{
   
    render(){


        return(

        <Fragment>
            <MetaTags>
              <title>SouthAsiaConsultancy  | Gallery </title>
              <meta
                name="description"
                content="Organic Food React JS Template."
              />
            </MetaTags>
            <LayoutOne>

           <div className="gallery-page">
           <SectionHeroBanner title={'Gallery'} className={'sectionGalleryBg'}/>
            <SectionNews />
           <SectionSliderGallery/>
            <SectionBusiness />
            </div>
            </LayoutOne>
        </Fragment>

        );
    }
}

export default Gallery;   