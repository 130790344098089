import MainTitle from "components/Common/MainTitle";
import ServiceButton from "components/Common/ServiceButton";
import React from "react";

function SectionCapable({CapableData}) {

  return (
    <>
      <div className="">
        <MainTitle text={"We are capable to provide"} position={'start'} width={'80px'} />
        <div className="row">
          {
            CapableData?.map((data)=>(<>
               <div className="col-md-6">

<ServiceButton
  title={data?.title}
/>
</div>
            
            
            </>))
          }
          
        </div>
      </div>
    </>
  );
}

export default SectionCapable;
