import React from 'react'
import Logo from '../../assets/images/AwardMask.png'

function SectionGroupAwards() {
    return (
        <>
        <div className='SectionGroupAbout'>
            <div className='container py-4'>

<div className='d-flex justify-content-center '>
<h2 className='text-white'>  Awards      </h2>
</div>
<div className='row '>

<div className='col-md-6'>
    <div className='card-group-companies d-flex align-items-center p-4' >
  <img src={Logo} alt='logo' className='card-group-companies-img' />
  <p className='primary-color mx-2 mt-3'>  Our Offshore  O&M Project of Rig Sagar Shakti honored with the  Best Performing rig in 2022 awarded by Chairman ONGC at Goa</p>
    </div>
</div>
<div className='col-md-6'>
    <div className='card-group-companies d-flex align-items-center p-4' >
  <img src={Logo} alt='logo' className='card-group-companies-img' />
  <p className='primary-color mx-2 mt-3'> 
  Again in this year on 15th August 23 Our O&M Project of Drilling Rig CW-IX (Cambay Asset)  got best  performing drilling rig award from Chairman ONGC at dehradun .
  </p>
    </div>
</div>

</div>
            </div>
        </div>
        </>
    )
}

export default SectionGroupAwards
