import React from 'react'
import MainTitle from '../Common/MainTitle'
import Card from 'react-bootstrap/Card';
import CardImg1 from '../../assets/images/Expertise1.png'
import CardImg2 from '../../assets/images/Expertise2.png'
import CardImg3 from '../../assets/images/Expertise3.png'
function SectionOurExpertise() {

    const CardData = [{
        CardImg: CardImg1 , 
        Title : 'Powerful & proficient management team to perform all over glob'
     } , 
     {
        CardImg: CardImg2 , 
        Title : 'Experts of Operation & Maintenance of Offshore & Onshore Drilling , Workover Rigs , exploration , production & associated services over 15 years.'
    } ,
    {
        CardImg: CardImg3 , 
        Title : 'Powerful and resourceful logistic & HR team in South Asia & India Region'
    }


]
    return (
       <>
      <div className='container'>
      
 <MainTitle text={'Our Expertise'} className={'text-center'} width={'119px'} position={'center'}/>
      
        <div className='row py-4'>

        {
  CardData?.map((card, index) => (
    <div className="col-md-4" key={index}>
   <Card  className='card-our-expertise'>
      <Card.Img variant="top" src={card?.CardImg}  />
      <Card.Body>
        <Card.Title className='SectionAboutCardTitle'>{card?.Title}</Card.Title>
        </Card.Body>
    </Card>
    </div>
  ))
}

  
        </div>
        </div> 
       
       </> 
    )
}

export default SectionOurExpertise
