import React from "react";
import MainTitle from "../Common/MainTitle";
import RelatedImg1 from "../../assets/images/Releated1.png";
import RelatedImg2 from "../../assets/images/Releated2.png";
import ServiceQuestion from "./ServiceQuestion";
// import AccordionCustom from "./Accordion";
// import Accordion from 'react-bootstrap/Accordion';
function RelatedProjects() {
  // const accordionData = [
  //   {
  //     title: "What is Lorem Ipsum?",
  //     content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  //   },
  //   {
  //     title: "What is Lorem Ipsum?",
  //     content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  //   },
  //   {
  //     title: "What is Lorem Ipsum?",
  //     content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  //   },
  //   {
  //     title: "What is Lorem Ipsum?",
  //     content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  //   },
  //   {
  //     title: "What is Lorem Ipsum?",
  //     content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
  //   },
  // ];
  return (
    <>
      <MainTitle
        text={"Related Projects"}
        className={"text-center  RelatedMarginTop"}
        position={"center"}
        width={"171px"}
      />
      <div className="container">
        <div className="row py-4 ">
          <div className="col-md-6">
            <img src={RelatedImg1} className="img-fluid" alt="Project1" />
          </div>
          <div className="col-md-6">
            <img src={RelatedImg2} className="img-fluid" alt="Project2" />
          </div>
        </div>

        {/* <div className="container row pt-2 py-4 d-flex justify-content-center"> */}
          <MainTitle text={"Have Some Questions?"} className={'text-center'} position={'center'} width={'81px'} />
        {/* </div> */}
        <div className="py-1">
          {/* <AccordionCustom accordionData={accordionData} /> */}
          <ServiceQuestion />
        </div>
      </div>
    </>
  );
}

export default RelatedProjects;
