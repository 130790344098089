import MainTitle from "components/Common/MainTitle";
import React from "react";
import ON_GC_IMG from "../../assets/images/ONGC.png";
function SectionOngoingProject() {
  const OnGoingProjectData = [
    "O&M Of 1 Jack up Drilling Rig with Experienced Manpower In ONGC, MumbaiOffshore [Rig name- 1. Sagar Shakti]",
    "O&M Of Drilling Land Rig of ONGC Cambay Asset, Gujarat , India.",
    "O&M 2 Work Over Rigs Of ONGC Karaikal Asset, Puducherry, India",
  ];
  const OnGoingProjectContent = [
    "O&M Of 8 Workover Rigs of ONGC Ankleshwar Asset, Gujarat, India.",
    "O&M Of 3 Workover Rigs of ONGC Ahmedabad Asset, Gujarat, India.",
    "O&M Of 3 Workover Rigs of Oil India Ltd, Duliajan, Assam, India",
    "Exploration and Production of South Patan Field, Gujarat, India.",
  ];
  return (
    <>
      <div className="container pt-4 ">
      
          <MainTitle text="Ongoing Projects" className={'text-center'} position={'center'} width={'171px'}  />

      
        <div className="row">
          <div className="col-md-3">
            <img src={ON_GC_IMG} alt="img" className="img-fluid" />
          </div>
          <div className="col-md-8">
            {OnGoingProjectData?.map((data, index) => (
              <div className="d-flex" key={index}>
                <div className=" NewsIcon">
              <i className="fa fa-circle primary-color" aria-hidden="true" ></i>

                </div>
                <li className="projectList ls-none ">{data}</li>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          {OnGoingProjectContent?.map((content, index) => (
            <div className="d-flex" key={index}>
              <div className=" NewsIcon"></div>
              <li className="projectList ">{content}</li>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionOngoingProject;
